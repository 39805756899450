import {
  allOrdersIcon,
  cancelOrderIcon,
  chooseFrameBlackIcon,
  chooseFrameLightIcon,
  choosePaperBlackIcon,
  choosePaperLightIcon,
  createPaperIcon,
  cropIcon,
  deliveredIcon,
  dispatchIcon,
  galleryBlackIcon,
  leftRotateIcon,
  listOfPaersIcon,
  moneyIcon,
  moveIcon,
  paperEditIcon,
  pendingIcon,
  resetIcon,
  resizeBlackIcon,
  resizeLightIcon,
  rightRotateIcon,
  selectBorderBlackIcon,
  selectBorderLightIcon,
  tickIcon,
  zoomInIcon,
  zoomOutIcon,
} from 'resources/images';

// routename
export const routeNames = {
  home: '/',
  uploadOrChangeImage: '/uploadorchangeimage',
  imageSizeAndCrop: '/imagesize&crop',
  selectBorder: '/selectborder',
  choosePaper: '/choosepaper',
  chooseFrame: '/chooseframe',
  checkout: '/checkout',
  signup: '/signup',
  login: '/login',
  forgotPassword: '/forgotpassword',
  resetPassword: '/resetpassword',
  editProfile: '/editprofile',

  // Admin
  adminLogin: '/adminlogin',
  adminDashboard: '/admindashboard',

  // Papers
  createPaper: '/createpaper',
  listOfPapers: '/listofpapers',
  editPaper: '/editpaper',

  // price
  addPrice: '/addprice',

  // orders
  allOrders: '/allorders',
};

// menu data
export const menuData = [
  {
    activeIcon: galleryBlackIcon,
    inActiveIcon: galleryBlackIcon,
    title: 'Upload or Change Image',
    descp: 'Upload an image to unlock rest of the customizations',
    route: routeNames.uploadOrChangeImage,
  },
  {
    activeIcon: resizeBlackIcon,
    inActiveIcon: resizeLightIcon,
    title: 'Image Size & Crop',
    descp: 'Upload image to adjust its size',
    route: routeNames.imageSizeAndCrop,
  },
  {
    activeIcon: selectBorderBlackIcon,
    inActiveIcon: selectBorderLightIcon,
    title: 'Select Border',
    descp: `You’ve not selected any border`,
    route: routeNames.selectBorder,
  },
  {
    activeIcon: choosePaperBlackIcon,
    inActiveIcon: choosePaperLightIcon,
    title: 'Choose Paper',
    descp: 'You’ve not selected any paper',
    route: routeNames.choosePaper,
  },
  // {
  //   activeIcon: chooseFrameBlackIcon,
  //   inActiveIcon: chooseFrameLightIcon,
  //   title: 'Choose Frame',
  //   descp: 'You’ve not selected any frame',
  //   route: routeNames.chooseFrame,
  // },
];

export const editImageIconsData = [
  {
    icon: cropIcon,
    name: 'cropIcon',
  },
  {
    icon: moveIcon,
    name: 'moveIcon',
  },
  {
    icon: zoomInIcon,
    name: 'zoomInIcon',
  },
  {
    icon: zoomOutIcon,
    name: 'zoomOutIcon',
  },
  {
    icon: leftRotateIcon,
    name: 'leftRotateIcon',
  },
  {
    icon: rightRotateIcon,
    name: 'rightRotateIcon',
  },

  {
    icon: resetIcon,
    name: 'resetIcon',
  },
  {
    icon: tickIcon,
    name: 'checkIcon',
  },
];

export const tabsData = [
  { name: 'Equal Sides', value: 'Equal' },
  { name: 'Custom Sides', value: 'Custom' },
];

export const dimensionsData = [
  { name: 'Millimetres', value: 'mm' },
  { name: 'Centimetres', value: 'cm' },
];

export const borderPlacementData = [
  {
    name: 'Outside',
    value: 'Outside',
  },
  {
    name: 'Inside',
    value: 'Inside',
  },
];

export const adminDashBoardData = [
  {
    title: 'Create Paper',
    link: routeNames.createPaper,
    image: createPaperIcon,
  },
  {
    title: 'List Of Papers',
    link: routeNames.listOfPapers,
    image: listOfPaersIcon,
  },
  {
    title: 'Add Price',
    link: routeNames.addPrice,
    image: moneyIcon,
  },

  {
    title: 'Orders',
    link: routeNames.allOrders,
    image: allOrdersIcon,
  },
];

// delivery status data
export const statusData = [
  { title: 'Delivered', icon: deliveredIcon },
  { title: 'Pending', icon: pendingIcon },
  { title: 'Cancelled', icon: cancelOrderIcon },
  { title: 'Dispatch', icon: dispatchIcon },
];

// ratios and prices data

export const ratiosData = [
  {
    '3:2': [
      { widthStandard: 15, heightStandard: 10, price: 170 },
      { widthStandard: 30, heightStandard: 20, price: 231 },
      { widthStandard: 45, heightStandard: 30, price: 350 },
      { widthStandard: 60, heightStandard: 40, price: 491 },
      { widthStandard: 75, heightStandard: 50, price: 687 },
      { widthStandard: 90, heightStandard: 60, price: 982 },
      { widthStandard: 105, heightStandard: 70, price: 1305 },
      { widthStandard: 120, heightStandard: 80, price: 1670 },
      { widthStandard: 150, heightStandard: 100, price: 2624 },
    ],
  },
  {
    '4:3': [
      { widthStandard: 20, heightStandard: 15, price: 207 },
      { widthStandard: 28, heightStandard: 21, price: 231 },
      { widthStandard: 40, heightStandard: 30, price: 336 },
      { widthStandard: 60, heightStandard: 45, price: 533 },
      { widthStandard: 80, heightStandard: 60, price: 870 },
      { widthStandard: 100, heightStandard: 75, price: 1333 },
      { widthStandard: 120, heightStandard: 90, price: 1880 },
    ],
  },
  {
    'Square(1:1)': [
      { widthStandard: 10, heightStandard: 10, price: 146 },
      { widthStandard: 20, heightStandard: 20, price: 219 },
      { widthStandard: 30, heightStandard: 30, price: 308 },
      { widthStandard: 40, heightStandard: 40, price: 378 },
      { widthStandard: 50, heightStandard: 50, price: 505 },
      { widthStandard: 60, heightStandard: 60, price: 673 },
      { widthStandard: 70, heightStandard: 70, price: 1069 },
      { widthStandard: 80, heightStandard: 80, price: 1150 },
      { widthStandard: 90, heightStandard: 90, price: 1296 },
      { widthStandard: 100, heightStandard: 100, price: 1737 },
    ],
  },
  {
    Standard: [
      { widthStandard: 18, heightStandard: 13, price: 182 },
      { widthStandard: 24, heightStandard: 18, price: 252 },
      { widthStandard: 30, heightStandard: 24, price: 280 },
      { widthStandard: 35, heightStandard: 28, price: 322 },
      { widthStandard: 50, heightStandard: 40, price: 434 },
      { widthStandard: 60, heightStandard: 50, price: 575 },
      { widthStandard: 70, heightStandard: 50, price: 645 },
      { widthStandard: 90, heightStandard: 70, price: 1122 },
      { widthStandard: 100, heightStandard: 70, price: 1249 },
      { widthStandard: 140, heightStandard: 100, price: 2414 },
    ],
  },

  {
    'A Size (5:7)': [
      { widthStandard: 10.5, heightStandard: 14.8, price: 182 },
      { widthStandard: 14.8, heightStandard: 21.0, price: 207 },
      { widthStandard: 21.0, heightStandard: 29.7, price: 231 },
      { widthStandard: 29.7, heightStandard: 42.0, price: 336 },
      { widthStandard: 42.0, heightStandard: 59.4, price: 491 },
      { widthStandard: 59.4, heightStandard: 84.1, price: 898 },
      { widthStandard: 84.1, heightStandard: 118.9, price: 1740 },
    ],
  },
  {
    '(16:9)': [
      { widthStandard: 16, heightStandard: 9, price: 170 },
      { widthStandard: 32, heightStandard: 18, price: 266 },
      { widthStandard: 48, heightStandard: 27, price: 336 },
      { widthStandard: 64, heightStandard: 36, price: 462 },
      { widthStandard: 80, heightStandard: 45, price: 673 },
      { widthStandard: 112, heightStandard: 63, price: 1263 },
      { widthStandard: 128, heightStandard: 72, price: 1614 },
      { widthStandard: 144, heightStandard: 81, price: 2021 },
      { widthStandard: 160, heightStandard: 90, price: 2484 },
      // { width: 176, heightStandard: 99, price: 3228 },
    ],
  },
];
