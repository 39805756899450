import React from 'react';
import { Navigate } from 'react-router-dom';
import { routeNames } from 'constants';

const GuestGuard = (props) => {
  const { children } = props;
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (userData) {
    return <Navigate to={routeNames.home} replace />;
  }

  return <>{children}</>;
};

export default GuestGuard;
