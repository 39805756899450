import React, { useContext } from 'react';
import { useNetWorkStatusContext } from 'hooks/usenetworkcontext';
import { useStrings } from 'providers/stringsprovider';
import styles from './styles.module.css';

const NetWorkInfo = () => {
  const { isOnline, showMessage } = useNetWorkStatusContext();
  const { strings } = useStrings();

  if (showMessage) {
    return (
      <div
        className={`${styles.networkToasterStyle} ${
          isOnline ? styles.onLine : styles.offLine
        }`}
      >
        <p>{isOnline ? strings.onLine : strings.offLine}</p>
      </div>
    );
  }

  return null;
};

export { NetWorkInfo };
