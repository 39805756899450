import { NetworkStatusContext } from 'providers/networkprovider';
import { useContext } from 'react';

export const useNetWorkStatusContext = () => {
  const context = useContext(NetworkStatusContext);

  if (!context)
    throw new Error('useLanguage context must be use inside LanguageProvider');

  return context;
};
