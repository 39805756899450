import React, { useEffect, useState } from 'react';
import {
  customFitIcon,
  downArrowBlackIcon,
  infoIcon,
  lockIcon,
  perfectFitIcon,
  standardFitIcon,
  unlockIcon,
} from 'resources/images';
import { Image } from 'components/common/image';
import classNames from 'classnames';
import { RadioButton } from 'components/common/radiobutton';
import { Button } from 'components/common/button';
import { Input } from 'components/common/input';
import Capsule from 'components/common/capsule';
import RangeInput from 'components/common/rangeInput';
import { useStrings } from 'providers/stringsprovider';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { getImageDimensions } from 'functions';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from 'constants';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { ratiosData } from 'constants';
import { Loader } from 'components/common/loader';
import { useBeforeUnload } from 'hooks/usebeforeunload';
import styles from './styles.module.css';

const ImageSizeOrCrop = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {} = props;
  const { strings } = useStrings();
  const {
    productData,
    setProductData,
    setAspectRatio,
    aspectRatio,
    sizePercentage,
    setSizePercentage,
    setImageUrl,
    imageUrl,
    isCropperVisible,
    setIsCropperVisible,
    handleCrop,
    width,
    setWidth,
    height,
    setHeight,
    setScaleValue,
    selectedRatio,
    setSelectedRatio,
    onBeforeLoad,
    setOnBeforeUnLoad,
    lockWithAndHeight,
    setLockWithAndHeight,
  } = useAppDataContext();

  const [selectedTab, setSelectedTab] = useState(productData.sizes.size_type);

  const [size, setSize] = useState(sizePercentage);
  const [selectedUnits, setSelectedUnits] = useState(
    productData?.sizes.size_units === 'cm' ? 'Centimetres' : 'Millimetres'
  );

  const [ratio, setRatio] = useState(aspectRatio ? aspectRatio : '');

  const [showLoader, setShowLoader] = useState(false);

  const [showRatioDropDown, setShowDropDownShow] = useState(false);

  const [sliderValue, setSliderValue] = useState(0);

  const [selectedRatioRecords, setSelectedRatioRecords] = useState(null);

  const [startPercent, setStartPercent] = useState(10);
  const [stepSize, setStepSize] = useState();
  const [maxSliderValue, setMaxSliderValue] = useState();

  // hooks
  useBeforeUnload(onBeforeLoad);

  const ImageSizeCropTabs = [
    {
      label: strings.perfectFit,
      icon: perfectFitIcon,
      value: 'Perfect',
    },
    {
      label: 'Custom fit',
      icon: customFitIcon,
      value: 'Custom',
    },
    {
      label: 'Standard fit',
      icon: standardFitIcon,
      value: 'Standard',
    },
  ];
  const SizeUnits = [
    { name: 'Millimetres', value: 'mm' },
    { name: 'Centimetres', value: 'cm' },
  ];

  const ASPRatios = [
    {
      name: '3:2',
      value: (3 / 2).toFixed(2),
    },
    {
      name: '4:3',
      value: (4 / 3).toFixed(2),
    },
    {
      name: 'Square(1:1)',
      value: (1 / 1).toFixed(2),
    },
    {
      name: 'Standard',
      value: (18 / 13).toFixed(2),
    },

    {
      name: 'A Size (5:7)',
      value: (5 / 7).toFixed(2),
    },

    {
      name: '(16:9)',
      value: (16 / 9).toFixed(2),
    },

    // {
    //   name: 'Popular Digital (3:4)',
    //   value: (3 / 4).toFixed(2),
    // },

    // {
    //   name: 'Panoramic (1:2)',
    //   value: (1 / 2).toFixed(2),
    // },
    // {
    //   name: 'Ultra Panoramic (1:3)',
    //   value: (1 / 3).toFixed(2),
    // },
  ];

  useEffect(() => {
    setShowLoader(true);

    setTimeout(() => {
      setShowLoader(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (selectedTab === 'Standard') {
      const defaultRatio = ASPRatios.find(
        (ratio) => ratio.name === selectedRatio
      );
      if (defaultRatio) {
        setSelectedRatio(defaultRatio.name);
        setRatio(Number(defaultRatio.value).toFixed(2));
      }
    }
  }, [selectedTab, selectedRatio]);

  // find length of the ratio records

  useEffect(() => {
    const ratioRecord = ratiosData.find((ratio) => ratio[selectedRatio]);

    if (ratioRecord) {
      const dimensionsArray = ratioRecord[selectedRatio];
      setSelectedRatioRecords(dimensionsArray);
    } else {
      console.log(`${selectedRatio} not found in ratiosData.`);
    }
  }, [selectedRatio]);

  useEffect(() => {
    if (selectedRatioRecords) {
      const index = selectedRatioRecords.findIndex((value) => {
        if (width > height) {
          return (
            value.widthStandard ===
              Number(
                productData.sizes.size_units === 'cm'
                  ? productData.sizes.width
                  : productData.sizes.width / 10
              ) &&
            value.heightStandard ===
              Number(
                productData.sizes.size_units === 'cm'
                  ? productData.sizes.height
                  : productData.sizes.height / 10
              )
          );
        } else {
          return (
            value.heightStandard ===
              Number(
                productData.sizes.size_units === 'cm'
                  ? productData.sizes.width
                  : productData.sizes.width / 10
              ) &&
            value.widthStandard ===
              Number(
                productData.sizes.size_units === 'cm'
                  ? productData.sizes.height
                  : productData.sizes.height / 10
              )
          );
        }
      });
      // Calculate the max value for the slider based on the selected ratio's records
      setMaxSliderValue((selectedRatioRecords?.length - 1) * 10);
      // Adjust the step size based on the number of records
      setStepSize(selectedRatioRecords?.length > 1 ? 10 : 1);
      setSliderValue(index * (selectedRatioRecords?.length > 1 ? 10 : 1));
    }
  }, [selectedRatioRecords]);

  // Function to handle slider change

  const handleSliderChange = (event) => {
    const newValue = Number(event.target.value);
    setSliderValue(newValue);

    const index = Math.round(newValue / stepSize);

    if (index >= 0 && index < selectedRatioRecords?.length) {
      const { widthStandard, heightStandard } = selectedRatioRecords[index];

      if (selectedRatio === 'Standard') {
        if (width > height) {
          setAspectRatio(widthStandard / heightStandard);
        } else {
          setAspectRatio(heightStandard / widthStandard);
        }
      }

      if (productData.sizes.size_units === 'cm') {
        if (width > height) {
          setProductData((prev) => ({
            ...prev,
            sizes: {
              ...prev.sizes,
              width: Number(widthStandard).toFixed(1),
              height: Number(heightStandard).toFixed(1),
            },
            paper_border: {
              ...prev.paper_border,
              border_value: '',
            },
          }));
        } else {
          setProductData((prev) => ({
            ...prev,
            sizes: {
              ...prev.sizes,
              width: Number(heightStandard).toFixed(1),
              height: Number(widthStandard).toFixed(1),
            },
            paper_border: {
              ...prev.paper_border,
              border_value: '',
            },
          }));
        }
      } else {
        if (width > height) {
          setProductData((prev) => ({
            ...prev,
            sizes: {
              ...prev.sizes,
              width: Number(widthStandard).toFixed(1) * 10,
              height: Number(heightStandard).toFixed(1) * 10,
            },
            paper_border: {
              ...prev.paper_border,
              border_value: '',
            },
          }));
        } else {
          setProductData((prev) => ({
            ...prev,
            sizes: {
              ...prev.sizes,
              width: Number(heightStandard).toFixed(1) * 10,
              height: Number(widthStandard).toFixed(1) * 10,
            },
            paper_border: {
              ...prev.paper_border,
              border_value: '',
            },
          }));
        }
      }
    }
    setOnBeforeUnLoad(true);
  };

  useEffect(() => {
    if (selectedTab === 'Standard') {
      setProductData((prev) => ({
        ...prev,
        sizes: {
          ...prev.sizes,
          width: '',
          height: '',
        },
      }));
    }
  }, []);

  useEffect(() => {
    if (productData.sizes.size_type === '') {
      getImageDimensions(productData.image)
        .then((dimensions) => {
          if (productData.sizes.size_type !== 'Standard') {
            setAspectRatio(
              Number(dimensions.cm.width) / Number(dimensions.cm.height)
            );
          }
          getAspectRatios(
            productData.sizes.size_units,
            Number(dimensions.cm.width) / Number(dimensions.cm.height)
          );
          setProductData({
            ...productData,
            sizes: { ...productData.sizes, size_type: 'Perfect' },
          });
          setSelectedTab(
            productData.sizes.size_type
              ? productData.sizes.size_type
              : 'Perfect'
          );
        })
        .catch((error) => {
          console.error('Error getting image dimensions:', error);
        });
    }
  }, []);

  useEffect(() => {
    getImageDimensions(
      productData.edited_image !== ''
        ? productData.edited_image
        : productData.image
    )
      .then((dimensions) => {
        if (productData.sizes.size_type === 'Custom') {
          if (selectedTab === 'Custom') {
            setAspectRatio(
              Number(dimensions.cm.width) / Number(dimensions.cm.height)
            );
            getAspectRatios(
              productData.sizes.size_units,
              Number(dimensions.cm.width) / Number(dimensions.cm.height)
            );
          }
        }
        if (selectedTab === 'Standard') {
          getAspectRatios(
            productData.sizes.size_units,
            Number(dimensions.cm.width) / Number(dimensions.cm.height)
          );
        }
      })
      .catch((error) => {
        console.error('Error getting image dimensions:', error);
      });
  }, [imageUrl]);

  useEffect(() => {
    setProductData({
      ...productData,
      sizes: { ...productData.sizes, size_type: selectedTab },
    });
  }, [selectedTab]);

  useEffect(() => {
    if (
      productData.sizes.size_units &&
      aspectRatio &&
      selectedTab === 'Perfect'
    ) {
      getAspectRatios(productData.sizes.size_units, aspectRatio);
    }
  }, [productData.sizes.size_units]);

  useEffect(() => {
    if (selectedTab === 'Perfect') {
      setProductData((prev) => ({
        ...prev,
        sizes: {
          ...prev.sizes,
          width: Number(((size * width) / 100).toFixed(1)),
          height: Number(((size * height) / 100).toFixed(1)),
        },
      }));
    } else if (selectedTab === 'Custom' && productData.edited_image !== '') {
      setProductData((prev) => ({
        ...prev,
        sizes: {
          ...prev.sizes,
          width: Number(((size * width) / 100).toFixed(0)),
          height: Number(((size * height) / 100).toFixed(0)),
        },
      }));
    }

    if (productData.sizes.size_units === 'cm') {
      if (width / 10 > 10 && height / 10 > 10) {
        setStartPercent(10);
      } else {
        if (width > height) {
          setStartPercent((100 / height) * 10);
        } else {
          setStartPercent((100 / width) * 10);
        }
      }
    } else {
      if (width / 10 > 100 && height / 10 > 100) {
        setStartPercent(10);
      } else {
        if (width > height) {
          setStartPercent((1000 / height) * 10);
        } else {
          setStartPercent((1000 / width) * 10);
        }
      }
    }

    setOnBeforeUnLoad(true);
  }, [width, height]);
  // useEffect(() => {
  //   if (selectedTab === 'Custom' && productData.edited_image !== '') {
  //     setProductData((prev) => ({
  //       ...prev,
  //       sizes: {
  //         ...prev.sizes,
  //         width: Number(((startPercent * width) / 100).toFixed(0)),
  //         height: Number(((startPercent * height) / 100).toFixed(0)),
  //       },
  //     }));
  //   }
  //   setOnBeforeUnLoad(true);
  // }, [startPercent]);

  const handleTabChange = (tab) => {
    // setSize((productData.sizes.width / width) * 100);

    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 500);

    setIsCropperVisible(false);
    getImageDimensions(productData.image)
      .then((dimensions) => {
        if (tab !== 'Standard') {
          setAspectRatio(
            Number(dimensions.cm.width) / Number(dimensions.cm.height)
          );
        }
        getAspectRatios(
          productData.sizes.size_units,
          Number(dimensions.cm.width) / Number(dimensions.cm.height)
        );
      })
      .catch((error) => {
        console.error('Error getting image dimensions:', error);
      });
    if (tab === 'Perfect') {
      // loader

      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 500);

      setImageUrl(URL.createObjectURL(productData.image));
      setRatio('');
      setProductData((prev) => ({
        ...prev,
        edited_image: '',
        sizes: {
          ...prev.sizes,
          width: Number(((size * width) / 100).toFixed(1)),
          height: Number(((size * height) / 100).toFixed(1)),
          aspect_ratio: '',
        },
      }));
    } else if (tab === 'Custom') {
      // loader

      setShowLoader(true);
      setTimeout(() => {
        setShowLoader(false);
      }, 500);
      setAspectRatio(width / height);
      setImageUrl(URL.createObjectURL(productData.image));
      setProductData((prev) => ({
        ...prev,
        edited_image: '',
        sizes: {
          ...prev.sizes,
          width: Number(((size * width) / 100).toFixed(1)),
          height: Number(((size * height) / 100).toFixed(1)),
          aspect_ratio: '',
        },
      }));
      setRatio('');
    } else if (tab === 'Standard') {
      setSliderValue(0);
      setImageUrl(URL.createObjectURL(productData.image));

      setRatio((3 / 2).toFixed(2));
      if (width > height) {
        setAspectRatio(3 / 2);
        setSelectedRatio('3:2');
      } else {
        setAspectRatio(2 / 3);
        setSelectedRatio('3:2');
      }

      // Ensure that selectedRatioRecords are set correctly
      const ratioRecord = ratiosData.find((ratio) => ratio[selectedRatio]);
      if (ratioRecord) {
        const dimensionsArray = ratioRecord[selectedRatio];
        setSelectedRatioRecords(dimensionsArray);
        // setSliderValue(0);

        // Set width and height based on default value
        const { widthStandard, heightStandard } = dimensionsArray[0];
        if (productData.sizes.size_units === 'cm') {
          if (width > height) {
            setProductData((prev) => ({
              ...prev,
              sizes: {
                ...prev.sizes,
                width: Number(widthStandard).toFixed(1),
                height: Number(heightStandard).toFixed(1),
              },
              edited_image: '',
              paper_border: {
                ...prev.paper_border,
                border_value: '',
              },
            }));
          } else {
            setProductData((prev) => ({
              ...prev,
              sizes: {
                ...prev.sizes,
                width: Number(heightStandard).toFixed(1),
                height: Number(widthStandard).toFixed(1),
              },
              edited_image: '',
              paper_border: {
                ...prev.paper_border,
                border_value: '',
              },
            }));
          }
        } else {
          if (width > height) {
            setProductData((prev) => ({
              ...prev,
              sizes: {
                ...prev.sizes,
                width: Number(widthStandard).toFixed(1) * 10,
                height: Number(heightStandard).toFixed(1) * 10,
              },
              edited_image: '',
              paper_border: {
                ...prev.paper_border,
                border_value: '',
              },
            }));
          } else {
            setProductData((prev) => ({
              ...prev,
              sizes: {
                ...prev.sizes,
                width: Number(heightStandard).toFixed(1) * 10,
                height: Number(widthStandard).toFixed(1) * 10,
              },
              edited_image: '',
              paper_border: {
                ...prev.paper_border,
                border_value: '',
              },
            }));
          }
        }
      }

      setIsCropperVisible(true);
    }
  };

  const getAspectRatios = (units, aspect) => {
    if (units === 'cm') {
      if (aspect < 1) {
        let widthData = 160 * aspect;
        if (Number(widthData.toFixed(0)) > 110) {
          setWidth(110);
          setHeight(110 / aspect);
        } else {
          setWidth(Number(widthData.toFixed(0)));
          setHeight(160);
        }
      } else {
        let heightData = 160 / aspect;

        if (Number(heightData.toFixed(0)) > 110) {
          setWidth(aspect * 110);
          setHeight(110);
        } else {
          setWidth(160);
          setHeight(Number(heightData.toFixed(0)));
        }
      }
    } else if (units === 'mm') {
      if (aspect < 1) {
        let widthData = 1600 * aspect;
        if (Number(widthData.toFixed(0)) > 1100) {
          setWidth(1100);
          setHeight(1100 / aspect);
        } else {
          setWidth(Number(widthData.toFixed(0)));
          setHeight(1600);
        }
      } else {
        let heightData = 1600 / aspect;

        if (Number(heightData.toFixed(0)) > 1100) {
          setWidth(aspect * 1100);
          setHeight(1100);
        } else {
          setWidth(1600);
          setHeight(Number(heightData.toFixed(0)));
        }
      }
    }
  };
  const handleUnlockValues = () => {
    let ratioAspect = productData.sizes.width / productData.sizes.height;
    let units = productData.sizes.size_units;
    if (units === 'cm') {
      if (ratioAspect < 1) {
        let widthData = 160 * ratioAspect;
        if (Number(widthData.toFixed(0)) > 110) {
          setWidth(110);
          setHeight(110 / ratioAspect);
        } else {
          setWidth(Number(widthData.toFixed(0)));
          setHeight(160);
        }
      } else {
        let heightData = 160 / ratioAspect;

        if (Number(heightData.toFixed(0)) > 110) {
          setWidth(ratioAspect * 110);
          setHeight(110);
        } else {
          setWidth(160);
          setHeight(Number(heightData.toFixed(0)));
        }
      }
    } else if (units === 'mm') {
      if (ratioAspect < 1) {
        let widthData = 1600 * ratioAspect;
        if (Number(widthData.toFixed(0)) > 1100) {
          setWidth(1100);
          setHeight(1100 / ratioAspect);
        } else {
          setWidth(Number(widthData.toFixed(0)));
          setHeight(1600);
        }
      } else {
        let heightData = 1600 / ratioAspect;

        if (Number(heightData.toFixed(0)) > 1100) {
          setWidth(ratioAspect * 1100);
          setHeight(1100);
        } else {
          setWidth(1600);
          setHeight(Number(heightData.toFixed(0)));
        }
      }
    }
    navigate(routeNames.selectBorder);
  };
  const renderTabs = () => {
    return (
      <section className={styles.tabsMainViewStyle}>
        <div className={styles.infoStyle}>
          <div className={styles.infoIconStyle}>
            <img
              src={infoIcon}
              alt="infoIcon"
              data-tooltip-id="my-tooltip-1"
              className={styles.imageStyle}
            />
          </div>
          <Tooltip
            id="my-tooltip-1"
            place="bottom"
            content={strings.imageSizeAndCropDescOne}
            style={{ width: '300px' }}
          />
        </div>
        <div className={styles.tabsViewStyle}>
          {ImageSizeCropTabs.map((tab, index) => (
            <div
              key={index}
              className={classNames(
                styles.tabViewStyle,
                selectedTab === tab.value && styles.activeTabStyle
              )}
              onClick={() => {
                setSelectedTab(tab.value);
                handleTabChange(tab.value);
              }}
            >
              <Image
                image={tab.icon}
                altText="Icon"
                customImageContainerStyle={styles.tabsIconStyle}
              />
              <p className={styles.tabLabelStyle}>{tab.label}</p>
            </div>
          ))}
        </div>
      </section>
    );
  };

  const renderAdjustSizeSection = () => {
    return (
      <React.Fragment>
        {selectedTab !== 'Custom' ? (
          <div className={styles.adjustSizeViewStyle}>
            <div className={styles.adjustSizeSubViewStyle}>
              <p className={styles.titleTextStyle}>Adjust Size</p>
              <p className={styles.ImageDimensionTextStyle}>{`${
                productData.sizes.width && productData.sizes.width
              }${
                productData.sizes.size_units
                  ? productData.sizes.size_units
                  : '--'
              } x ${productData.sizes.height && productData.sizes.height}${
                productData.sizes.size_units
                  ? productData.sizes.size_units
                  : '--'
              }`}</p>
            </div>

            {selectedTab === 'Standard' ? (
              <RangeInput
                minValue={0}
                maxValue={maxSliderValue}
                disabled={!selectedRatio}
                step={stepSize}
                value={sliderValue}
                onChange={handleSliderChange}
              />
            ) : (
              <RangeInput
                minValue={startPercent}
                maxValue={100}
                // disabled={size > 10 ? false : true}
                value={size}
                step={selectedTab === 'Standard' && 10}
                onChange={(e) => {
                  setSize(Number(e.target.value));
                  setSizePercentage(Number(e.target.value));
                  setScaleValue(1);
                  setProductData((prev) => ({
                    ...prev,
                    sizes: {
                      ...prev.sizes,
                      width: ((Number(e.target.value) * width) / 100).toFixed(
                        1
                      ),
                      height: ((Number(e.target.value) * height) / 100).toFixed(
                        1
                      ),
                    },
                    paper_border: {
                      ...prev.paper_border,
                      border_value: '',
                    },
                  }));
                  setOnBeforeUnLoad(true);
                }}
              />
            )}

            {selectedTab === 'Standard' && (
              <div className={styles.rangeIntervalStyle}>
                {selectedRatioRecords?.map((_, index) => (
                  <div key={index} className={styles.intervalBarStyle}></div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.adjustWithHightSectionStyle}>
            <Input
              type="number"
              label={strings.width}
              placeholder="Width"
              min={((startPercent * width) / 100).toFixed(0)}
              max={width > height ? width : height}
              value={productData.sizes.width}
              customLabelStyle={styles.inputLabelStyle}
              onChange={(e) => {
                if (lockWithAndHeight) {
                  if (
                    productData.sizes.size_units === 'cm'
                      ? e.target.value <=
                        (width > height
                          ? width
                          : width < height
                          ? width
                          : height)
                      : e.target.value <=
                        (width > height
                          ? width
                          : width < height
                          ? width
                          : height)
                  ) {
                    setScaleValue(1);
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        width: e.target.value,
                        height: (e.target.value / aspectRatio).toFixed(0),
                      },
                      paper_border: {
                        ...prev.paper_border,
                        border_value: '',
                      },
                    }));
                  }
                } else {
                  if (
                    productData.sizes.size_units === 'cm'
                      ? e.target.value <=
                        (width > height
                          ? width
                          : width < height
                          ? width
                          : height)
                      : e.target.value <=
                        (width > height
                          ? width
                          : width < height
                          ? width
                          : height)
                  ) {
                    setScaleValue(1);
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        width: e.target.value,
                      },
                      paper_border: {
                        ...prev.paper_border,
                        border_value: '',
                      },
                    }));
                  }
                }
              }}
              // disabled={lockWithAndHeight}
            />
            <Input
              type="number"
              label={strings.height}
              placeholder="Height"
              min={((startPercent * height) / 100).toFixed()}
              value={productData.sizes.height}
              customLabelStyle={styles.inputLabelStyle}
              onChange={(e) => {
                if (lockWithAndHeight) {
                  if (
                    productData.sizes.size_units === 'cm'
                      ? e.target.value <=
                        (width > height
                          ? width
                          : width < height
                          ? width
                          : height) /
                          aspectRatio
                      : e.target.value <=
                        (width > height
                          ? width
                          : width < height
                          ? width
                          : height) /
                          aspectRatio
                  ) {
                    setScaleValue(1);
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        width: (e.target.value * aspectRatio).toFixed(0),
                        height: e.target.value,
                      },
                      paper_border: {
                        ...prev.paper_border,
                        border_value: '',
                      },
                    }));
                  }
                } else {
                  if (
                    productData.sizes.size_units === 'cm'
                      ? e.target.value <=
                        (width > height
                          ? width
                          : width < height
                          ? width
                          : height) /
                          aspectRatio
                      : e.target.value <=
                        (width > height
                          ? width
                          : width < height
                          ? width
                          : height) /
                          aspectRatio
                  ) {
                    setScaleValue(1);
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        height: e.target.value,
                      },
                      paper_border: {
                        ...prev.paper_border,
                        border_value: '',
                      },
                    }));
                  }
                }
              }}
              // disabled={lockWithAndHeight}
            />
            <Image
              image={lockWithAndHeight ? lockIcon : unlockIcon}
              onClick={() => {
                setLockWithAndHeight(!lockWithAndHeight);
              }}
              customImageContainerStyle={styles.lockIconStyle}
            />
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderUnitsSection = () => {
    return (
      <div className={styles.unitsSectionStyle}>
        {SizeUnits.map((unit, index) => (
          <RadioButton
            key={index}
            label={unit.name}
            selected={selectedUnits}
            customLabelStyle={styles.radioBtnLabelStyle}
            onClick={() => {
              if (selectedUnits !== unit.name) {
                setSelectedUnits(unit.name);
                if (selectedTab === 'Perfect') {
                  if (unit.value === 'cm') {
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        width: ((size * width) / 1000).toFixed(0),
                        height: ((size * height) / 1000).toFixed(0),
                      },
                    }));
                  } else {
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        width: ((size * width) / 10).toFixed(0),
                        height: ((size * height) / 10).toFixed(0),
                      },
                    }));
                  }
                } else if (selectedTab === 'Custom') {
                  if (unit.value === 'cm') {
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        width: (productData.sizes.width / 10).toFixed(0),
                        height: (productData.sizes.height / 10).toFixed(0),
                      },
                    }));
                    setHeight(height / 10);
                    setWidth(width / 10);
                  } else {
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        width: (productData.sizes.width * 10).toFixed(0),
                        height: (productData.sizes.height * 10).toFixed(0),
                      },
                    }));
                    setHeight(height * 10);
                    setWidth(width * 10);
                  }
                } else if (selectedTab === 'Standard') {
                  if (unit.value === 'cm') {
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        width: (productData.sizes.width / 10).toFixed(0),
                        height: (productData.sizes.height / 10).toFixed(0),
                      },
                    }));
                  } else {
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        width: (productData.sizes.width * 10).toFixed(0),
                        height: (productData.sizes.height * 10).toFixed(0),
                      },
                    }));
                  }
                }
                setProductData((prev) => ({
                  ...prev,
                  sizes: { ...prev.sizes, size_units: unit.value },
                }));
              }
            }}
          />
        ))}
      </div>
    );
  };

  const renderRatioSection = () => {
    return (
      <React.Fragment>
        <div className={styles.ratiosViewStyle}>
          {ASPRatios.map((item, index) => (
            <Capsule
              key={index}
              label={item.name}
              onClick={() => {
                setIsCropperVisible(true);
                setSelectedRatio(item.name);
                setSliderValue(0);
                if (width > height) {
                  if (item.value === (5 / 7).toFixed(2)) {
                    setRatio(item.value);
                    setAspectRatio(item.value);
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        aspect_ratio: item.value,
                      },
                    }));
                  } else {
                    setRatio(item.value);
                    setAspectRatio(item.value);
                    setProductData((prev) => ({
                      ...prev,
                      sizes: { ...prev.sizes, aspect_ratio: item.value },
                    }));
                  }
                } else {
                  if (item.value === (5 / 7).toFixed(2)) {
                    setRatio(item.value);
                    setAspectRatio(item.value);
                    setProductData((prev) => ({
                      ...prev,
                      sizes: { ...prev.sizes, aspect_ratio: item.value },
                    }));
                  } else {
                    setRatio(item.value);
                    setAspectRatio(1 / item.value);
                    setProductData((prev) => ({
                      ...prev,
                      sizes: {
                        ...prev.sizes,
                        aspect_ratio: (1 / item.value).toFixed(2),
                      },
                    }));
                  }
                }

                const ratioRecord = ratiosData.find(
                  (ratio) => ratio[item.name]
                );
                if (ratioRecord) {
                  const dimensionsArray = ratioRecord[item.name];
                  setSelectedRatioRecords(dimensionsArray);

                  // Reset width and height based on the first record
                  if (dimensionsArray.length > 0) {
                    const { widthStandard, heightStandard } =
                      dimensionsArray[0];
                    if (productData.sizes.size_units === 'cm') {
                      if (width > height) {
                        setProductData((prev) => ({
                          ...prev,
                          sizes: {
                            ...prev.sizes,
                            width: widthStandard.toFixed(1),
                            height: heightStandard.toFixed(1),
                          },
                        }));
                      } else {
                        setProductData((prev) => ({
                          ...prev,
                          sizes: {
                            ...prev.sizes,
                            width: heightStandard.toFixed(1),
                            height: widthStandard.toFixed(1),
                          },
                        }));
                      }
                    } else {
                      if (width > height) {
                        setProductData((prev) => ({
                          ...prev,
                          sizes: {
                            ...prev.sizes,
                            width: widthStandard.toFixed(1) * 10,
                            height: heightStandard.toFixed(1) * 10,
                          },
                        }));
                      } else {
                        setProductData((prev) => ({
                          ...prev,
                          sizes: {
                            ...prev.sizes,
                            width: heightStandard.toFixed(1) * 10,
                            height: widthStandard.toFixed(1) * 10,
                          },
                        }));
                      }
                    }
                  }
                }
              }}
              containerStyle={classNames(
                styles.capsuleContainerStyle,
                ratio === item.value && styles.activeCapsuleStyle
              )}
              labelStyle={
                ratio === item.value
                  ? styles.activeLabelStyle
                  : styles.capsuleLableTextStyle
              }
            />
          ))}
        </div>

        <div className={styles.selectRationDropDownBlockStyle}>
          <Input
            label="Select Ratio"
            type="text"
            name="select ratio"
            placeholder="Select Ratio"
            value={selectedRatio}
            onFocus={() => setShowDropDownShow(!showRatioDropDown)}
            rightImg={downArrowBlackIcon}
            onClickRightImg={() => setShowDropDownShow(!showRatioDropDown)}
            customLabelStyle={styles.ratioLabelStyle}
            customInputStyle={styles.ratioInputStyle}
            customInputSubContainerStyle={styles.selectRatioInputStyle}
          />

          {showRatioDropDown && (
            <div className={styles.selectDropDownStyle}>
              {ASPRatios?.map((item, index) => {
                return (
                  <p
                    className={
                      selectedRatio === item?.name
                        ? styles.selectedRatioTextStyle
                        : styles.ratioTextStyle
                    }
                    key={index}
                    onClick={() => {
                      setIsCropperVisible(true);
                      setSelectedRatio(item.name);
                      setSliderValue(0);
                      if (width > height) {
                        if (item.value === (5 / 7).toFixed(2)) {
                          setRatio(item.value);
                          setAspectRatio(item.value);
                          setProductData((prev) => ({
                            ...prev,
                            sizes: {
                              ...prev.sizes,
                              aspect_ratio: item.value,
                            },
                          }));
                        } else {
                          setRatio(item.value);
                          setAspectRatio(item.value);
                          setProductData((prev) => ({
                            ...prev,
                            sizes: { ...prev.sizes, aspect_ratio: item.value },
                          }));
                        }
                      } else {
                        if (item.value === (5 / 7).toFixed(2)) {
                          setRatio(item.value);
                          setAspectRatio(item.value);
                          setProductData((prev) => ({
                            ...prev,
                            sizes: { ...prev.sizes, aspect_ratio: item.value },
                          }));
                        } else {
                          setRatio(item.value);
                          setAspectRatio(1 / item.value);
                          setProductData((prev) => ({
                            ...prev,
                            sizes: {
                              ...prev.sizes,
                              aspect_ratio: (1 / item.value).toFixed(2),
                            },
                          }));
                        }
                      }

                      const ratioRecord = ratiosData.find(
                        (ratio) => ratio[item.name]
                      );
                      if (ratioRecord) {
                        const dimensionsArray = ratioRecord[item.name];
                        setSelectedRatioRecords(dimensionsArray);

                        // Reset width and height based on the first record
                        if (dimensionsArray.length > 0) {
                          const { widthStandard, heightStandard } =
                            dimensionsArray[0];
                          if (productData.sizes.size_units === 'cm') {
                            if (width > height) {
                              setProductData((prev) => ({
                                ...prev,
                                sizes: {
                                  ...prev.sizes,
                                  width: widthStandard.toFixed(1),
                                  height: heightStandard.toFixed(1),
                                },
                              }));
                            } else {
                              setProductData((prev) => ({
                                ...prev,
                                sizes: {
                                  ...prev.sizes,
                                  width: heightStandard.toFixed(1),
                                  height: widthStandard.toFixed(1),
                                },
                              }));
                            }
                          } else {
                            if (width > height) {
                              setProductData((prev) => ({
                                ...prev,
                                sizes: {
                                  ...prev.sizes,
                                  width: widthStandard.toFixed(1) * 10,
                                  height: heightStandard.toFixed(1) * 10,
                                },
                              }));
                            } else {
                              setProductData((prev) => ({
                                ...prev,
                                sizes: {
                                  ...prev.sizes,
                                  width: heightStandard.toFixed(1) * 10,
                                  height: widthStandard.toFixed(1) * 10,
                                },
                              }));
                            }
                          }
                        }
                      }
                    }}
                  >
                    {item?.name}
                  </p>
                );
              })}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  const renderBtnSection = () => {
    return (
      <div className={styles.bottomBtnBlockStyle}>
        <Button
          title="Previous"
          customBtnTitleStyle={styles.prevBtnTitleStyle}
          customBtnContainerStyle={styles.prevBtnViewStyle}
          onClick={() => navigate(routeNames.uploadOrChangeImage)}
        />

        <Button
          title={isCropperVisible ? 'Save Changes' : strings.addChanges}
          disabled={
            productData.sizes.width >= 10 && productData.sizes.height >= 10
              ? false
              : true
          }
          customBtnContainerStyle={styles.btnViewStyle}
          onClick={() => {
            if (isCropperVisible) {
              handleCrop();
            } else {
              if (!lockWithAndHeight) {
                handleUnlockValues();
              } else {
                navigate(routeNames.selectBorder);
              }
            }
          }}
          customBtnTitleStyle={styles.addChangesBtnStyle}
        />
      </div>
    );
  };

  return (
    <main className={styles.containerStyle}>
      {renderTabs()}
      <p className={styles.noteTextStyle}>
        {selectedTab !== 'Standard'
          ? strings.imageSizeAndCropDescOne
          : strings.imageSizeAndCropDescTwo}
      </p>
      {selectedTab === 'Standard' && renderRatioSection()}
      {renderAdjustSizeSection()}
      {renderUnitsSection()}
      {renderBtnSection()}
      {showLoader && <Loader />}
    </main>
  );
};

export default ImageSizeOrCrop;
