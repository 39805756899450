import React, { useState } from 'react';
import { Image } from 'components/common/image';
import { menuData } from 'constants';
import {
  chevronLeftBlackIcon,
  chevronRightBlackIcon,
  chevronRightLightIcon,
} from 'resources/images';
import UploadOrChangeImage from 'pages/upload-or-changeImage';
import ImageSizeOrCrop from 'pages/imagesize-or-crop';
import SelectBorder from 'pages/select-border';
import ChoosePaper from 'pages/choose-paper';
import ChooseFrame from 'pages/choose-frame';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from 'constants';
import { useAppDataContext } from 'hooks/useappdatacontext';
import AlertModal from 'components/alert-modal';
import styles from './styles.module.css';

const Menu = () => {
  // routing:
  const navigate = useNavigate();
  const location = useLocation();
  const { productData, setProductData } = useAppDataContext();
  const [showAlert, setShowAlert] = useState(false);

  const handleRouting = (title) => {
    switch (title) {
      case 'Upload or Change Image':
        productData.image && navigate(routeNames.uploadOrChangeImage);
        break;
      case 'Image Size & Crop':
        productData.image && navigate(routeNames.imageSizeAndCrop);
        break;
      case 'Select Border':
        productData.image && navigate(routeNames.selectBorder);
        break;
      case 'Choose Paper':
        productData.image && navigate(routeNames.choosePaper);
        break;
      // case 'Choose Frame':
      //   navigate(routeNames.chooseFrame);
      //   break;
      default:
        navigate(routeNames.home);
    }
  };
  const handleBackClick = () => {
    if (location?.pathname === routeNames?.uploadOrChangeImage) {
      if (productData?.image) {
        setShowAlert(true);
      } else {
        navigate(routeNames.home);
      }
    } else if (location?.pathname === routeNames?.imageSizeAndCrop) {
      const sizeValues =
        productData?.sizes && Object.values(productData?.sizes);
      if (sizeValues?.some((item) => item !== '')) {
        setShowAlert(true);
      } else {
        navigate(routeNames.home);
      }
    } else {
      navigate(routeNames.home);
    }
  };
  const handleApplyChanges = () => {
    if (location?.pathname === routeNames?.uploadOrChangeImage) {
      setShowAlert(false);
      navigate(routeNames.imageSizeAndCrop);
    } else if (location?.pathname === routeNames?.imageSizeAndCrop) {
      setShowAlert(false);
      navigate(routeNames.selectBorder);
    }
  };
  const handleAlertYesClick = () => {
    if (location?.pathname === routeNames?.uploadOrChangeImage) {
      setShowAlert(false);
      navigate(routeNames.home);
    } else if (location?.pathname === routeNames?.imageSizeAndCrop) {
      setShowAlert(false);
      navigate(routeNames.home);
      setProductData((prev) => ({
        ...prev,
        sizes: { ...prev.sizes, width: '', height: '' },
      }));
    }
  };
  const renderTopSection = () => {
    return (
      <section
        className={
          location.pathname === routeNames.home
            ? styles.topBlockStyle
            : styles.supplementaryTopBlockStyle
        }
      >
        {location.pathname !== routeNames.home && (
          <Image
            image={chevronLeftBlackIcon}
            altText="chevronLeftBlackIcon"
            customImageContainerStyle={styles.leftArrowStyle}
            onClick={() => navigate(routeNames.home)}
          />
        )}
        <h5
          className={
            location.pathname === routeNames.home
              ? styles.menuTitleStyle
              : styles.supplementaryMenuTitleStyle
          }
        >
          {location.pathname === routeNames.home
            ? 'Giclée Printing'
            : location.pathname === routeNames.uploadOrChangeImage
            ? 'Upload or Change Image'
            : location.pathname === routeNames.imageSizeAndCrop
            ? 'Image Size & Crop'
            : location.pathname === routeNames.selectBorder
            ? 'Select Border'
            : location.pathname === routeNames.choosePaper
            ? 'Choose Paper'
            : // : location.pathname === routeNames.chooseFrame
              // ? 'Choose Frame'
              ''}
        </h5>
      </section>
    );
  };

  const renderBottomSection = () => {
    return (
      <section className={styles.bottomContainerStyle}>
        {menuData?.map((item, index) => {
          // const productData.image = location.pathname.includes(item?.route);
          return (
            <div
              key={index}
              className={styles.bottomBlockStyle}
              onClick={() => handleRouting(item?.title)}
            >
              <div className={styles.bottomSubBlockStyle}>
                <Image
                  image={
                    productData.image ? item.activeIcon : item.inActiveIcon
                  }
                  altText="tab-icon"
                  customImageContainerStyle={styles.menuImgStyle}
                  customImageStyle={styles.imageStyle}
                />
                <div className={styles.menuDetailsStyle}>
                  <p
                    className={
                      productData.image
                        ? styles.activeMenuItemTextStyle
                        : styles.menuItemTitleStyle
                    }
                  >
                    {item.title}
                  </p>
                  <p
                    className={
                      productData.image
                        ? styles.activeMenuSubTitleStyle
                        : styles.menuSubTitleStyle
                    }
                  >
                    {item.descp}
                  </p>
                </div>
              </div>
              <Image
                image={
                  productData.image
                    ? chevronRightBlackIcon
                    : chevronRightLightIcon
                }
                altText="right-icon"
                customImageContainerStyle={styles.rightArrowImageStyle}
              />
            </div>
          );
        })}
      </section>
    );
  };

  return (
    <main className={styles.menuContainerStyle}>
      {renderTopSection()}
      {
        location.pathname === routeNames.home ? (
          renderBottomSection()
        ) : location.pathname === routeNames.uploadOrChangeImage ? (
          <UploadOrChangeImage />
        ) : location.pathname === routeNames.imageSizeAndCrop ? (
          <ImageSizeOrCrop />
        ) : location.pathname === routeNames.selectBorder ? (
          <SelectBorder />
        ) : (
          location.pathname === routeNames.choosePaper && <ChoosePaper />
        )
        // : (
        //   location.pathname === routeNames.chooseFrame && <ChooseFrame />
        // )
      }
      <AlertModal
        showAlert={showAlert}
        descText={
          'Hey there! You didn’t apply the changes you made, are you sure you want to go back unsaved?'
        }
        // onClickApply={}
        onClickYes={() => {
          handleAlertYesClick();
        }}
        onClickApply={() => handleApplyChanges()}
      />
    </main>
  );
};

export default Menu;
