import { useContext } from 'react';
import { ToastContext } from 'providers/toasterprovider';

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (!context)
    throw new Error('useToastContext context must be use inside ToastProvider');

  return context;
};
