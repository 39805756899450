import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const addToCartApi = async (data) =>
  Promise.resolve(axios.post(endPoints.addToCart, data));

export const getAllCartItemsApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAllCartItems.replace('{id}', id)));

export const updateCartApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateCart, data));

export const deleteCartApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deleteCart.replace('{id}', id)));

export const purchaseApi = async (data) =>
  Promise.resolve(axios.post(endPoints.purchase, data));

export const getAllOrdersApi = async (id) =>
  Promise.resolve(axios.get(endPoints.getAllOrders.replace('{id}', id)));
