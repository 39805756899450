import React, { useEffect, useRef, useState } from 'react';
import { Image } from '../image';
import {
  cartIcon,
  closeIcon,
  downArrowBlackIcon,
  editUserIcon,
  logOutIcon,
  ordersIcon,
  userProfileIcon,
} from 'resources/images';
import { Button } from '../button';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'constants';
import Modal from '../modal';
import ProductSummary from 'components/product-summary';
import ShoppingCartCard from '../cards/cart';
import { useAuthContext } from 'hooks/useauthcontext';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { uploadImageApi } from 'networking/apis/common';
import { Loader } from '../loader';
import { useToastContext } from 'hooks/usetoastcontext';
import { useStrings } from 'providers/stringsprovider';
import { useCartDataContext } from 'hooks/usecartdatacontext';
import styles from './styles.module.css';
import { formatCurrency } from 'functions';

const Header = () => {
  // routing:
  const navigate = useNavigate();
  const { userData, logOut } = useAuthContext();
  const { productData, showLoader, resetProductData } = useAppDataContext();

  const { showToast } = useToastContext();
  const { strings } = useStrings();

  const {
    cartData,
    setCartData,
    setOrderedItemsData,
    uploadImage,
    updateCartItem,
    quantity,
    setQuantity,
    deleteCartItem,
    cartModal,
    setCartModal,
    priceCaluculation,
    showPriceModal,
    setShowPriceModal,
    showOrdersModal,
    setShowOrdersModal,
    orderedItemsData,
  } = useCartDataContext();

  const userProfileActionsData = [
    {
      name: strings.editProfile,
      image: editUserIcon,
    },
    {
      name: strings.orders,
      image: ordersIcon,
    },
    {
      name: strings.logOut,
      image: logOutIcon,
    },
  ];

  const [showProfile, setShowProfile] = useState(false);

  const showProfileRef = useRef();

  // onclick outside close user profile popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showProfileRef.current &&
        !showProfileRef.current.contains(event.target)
      ) {
        setShowProfile(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // tapOnProfileActionClick

  const tapOnProfileActionClick = (item) => {
    if (item === 'Edit profile') {
      navigate(routeNames.editProfile);
    } else if (item === 'Orders') {
      setShowOrdersModal(!showOrdersModal);
    } else if (item === 'Logout') {
      setCartData([]);
      setOrderedItemsData([]);
      resetProductData();
      logOut();
    }
  };

  // functio to get border dimesions
  const getBorderDescription = (paperBorder) => {
    const { border_type, border_place, border_value, border_units } =
      paperBorder;

    if (!border_value || !border_units) return '';

    return `${border_type} - ${border_place} - top:${border_value.top}${border_units} bottom:${border_value.bottom}${border_units} right:${border_value.right}${border_units} left:${border_value.left}${border_units}`;
  };

  // tap on AddCart Btn Click
  const tapOnAddToCart = () => {
    if (!userData) {
      navigate(routeNames.login);
    } else if (!productData.image) {
      showToast.error(strings.uploadImageError);
    } else if (!productData.paper) {
      showToast.error(strings.selectPaperError);
    } else {
      uploadImage();
    }
  };

  const renderDeskTopSection = () => {
    return (
      <div className={styles.desktopStyle}>
        <Image
          image={cartIcon}
          altText="cart-logo"
          customImageContainerStyle={styles.leftStyle}
          onClick={() => setCartModal(!cartModal)}
        >
          <p className={styles.cartCountTextStyles}>{cartData?.length}</p>
        </Image>

        <div
          className={styles.middleStyle}
          onClick={() => setShowPriceModal(true)}
        >
          <p className={styles.priceTextStyle}>{`Price - ${formatCurrency(
            priceCaluculation()
          )}`}</p>
          <Image
            image={downArrowBlackIcon}
            altText={strings.downArrow}
            customImageContainerStyle={styles.downArrowStyle}
          />
        </div>
        <div className={styles.cartAndUserViewStyle}>
          <Button
            title={strings.addToCart}
            customBtnContainerStyle={styles.addToCartBtnStyle}
            customBtnTitleStyle={styles.addToCartTitleStyle}
            onClick={() => tapOnAddToCart()}
          />
          {userData?.account_type === 'User' ? (
            <Image
              image={userProfileIcon}
              altText={strings.userProfile}
              onClick={(e) => setShowProfile(!showProfile)}
              customImageContainerStyle={styles.profileImgStyle}
            >
              {showProfile && (
                <div
                  className={styles.usersActionsBlockStyle}
                  ref={showProfileRef}
                >
                  <div className={styles.userDetailsStyle}>
                    <Image
                      image={userProfileIcon}
                      altText={strings.userProfile}
                      customImageContainerStyle={styles.profileImgStyle}
                    />
                    <div className={styles.userNameAndEmailStyle}>
                      <h5 className={styles.userNameStyle}>
                        {userData?.user_name}
                      </h5>
                    </div>
                  </div>

                  <div className={styles.userActionStyle}>
                    {userProfileActionsData?.map((item, index) => {
                      return (
                        <div
                          className={styles.userActionItemStyle}
                          key={index}
                          onClick={() => tapOnProfileActionClick(item?.name)}
                        >
                          <Image
                            image={item?.image}
                            altText={item?.name}
                            customImageContainerStyle={styles.profileImgStyle}
                          />
                          <p className={styles.userActionTextStyle}>
                            {item?.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </Image>
          ) : (
            <Button
              title={strings.login}
              customBtnContainerStyle={styles.addToCartBtnStyle}
              customBtnTitleStyle={styles.addToCartTitleStyle}
              onClick={() => navigate(routeNames.login)}
            />
          )}
        </div>
      </div>
    );
  };

  const renderMobileSection = () => {
    return (
      <div
        className={styles.mobilePriceSectionStyle}
        onClick={() => setShowPriceModal(true)}
      >
        <p className={styles.priceTextStyle}>{`${
          strings.price
        }  ${formatCurrency(priceCaluculation())}`}</p>
        <Image
          image={downArrowBlackIcon}
          altText={strings.downArrow}
          customImageContainerStyle={styles.downArrowStyle}
        />
      </div>
    );
  };

  const renderPriceModal = () => {
    return (
      <Modal
        isOpen={showPriceModal}
        customModalContentStyle={styles.modalContentStyle}
      >
        <ProductSummary
          totalPrice={formatCurrency(priceCaluculation())}
          headerTitle={strings.productSummary}
          onClickClose={() => setShowPriceModal(false)}
          onClickAddToCart={() => uploadImage()}
        />
      </Modal>
    );
  };

  const renderCartModal = () => {
    return (
      <Modal
        isOpen={cartModal}
        onClose={() => setCartModal(false)}
        customModalContentStyle={styles.cartModalContentStyle}
      >
        <div>
          <div className={styles.headerContainerStyle}>
            <p className={styles.headerTextStyle}>
              {`${strings.yourCart} (${
                cartData?.length ? cartData?.length : '0'
              })`}
            </p>
            <Image
              image={closeIcon}
              altText={strings.closeIcon}
              customImageContainerStyle={styles.imgStyle}
              onClick={() => setCartModal(false)}
            />
          </div>
          <div className={styles.cartDataBlockStyle}>
            {cartData.length > 0 ? (
              cartData?.map((item, index) => {
                return (
                  <ShoppingCartCard
                    key={index}
                    showCartActions={true}
                    image={item?.image}
                    altText={strings.frameImage}
                    cardTitle={`Giclée Frame & Print (${item?.sizes.width} ${item.sizes.size_units} x ${item?.sizes.height} ${item.sizes.size_units})`}
                    paper={item.paper.title}
                    border={getBorderDescription(item?.paper_border)}
                    totalPrice={`SEK ${(
                      item?.total_price * item?.quantity
                    ).toFixed(2)}`}
                    quantity={item?.quantity}
                    onClickPlus={() => {
                      updateCartItem(item?._id, item.quantity + 1);
                    }}
                    onClickMinus={() => {
                      if (item?.quantity > 1) {
                        updateCartItem(item?._id, item?.quantity - 1);
                      } else {
                        deleteCartItem(item?._id);
                      }
                    }}
                    onClickDelete={() => deleteCartItem(item?._id)}
                  />
                );
              })
            ) : (
              <p className={styles.noDataFoundTextStyle}>
                {strings.noDataInCartMessage}
              </p>
            )}
          </div>
        </div>
        {cartData?.length > 0 && (
          <div className={styles.btnContainerStyle}>
            <Button
              title={strings.createNew}
              onClick={() => {
                setCartModal(false);
                navigate(routeNames.home);
                resetProductData();
              }}
              customBtnContainerStyle={styles.createBtnStyle}
              customBtnTitleStyle={styles.createTitleStyle}
            />
            <Button
              title={strings.checkOut}
              customBtnContainerStyle={styles.checkOutBtnStyle}
              onClick={() => navigate(routeNames.checkout)}
            />
          </div>
        )}
      </Modal>
    );
  };

  const renderOrdersModalSection = () => {
    return (
      <Modal
        isOpen={showOrdersModal}
        onClose={() => setShowOrdersModal(false)}
        customModalContentStyle={styles.cartModalContentStyle}
      >
        <div>
          <div className={styles.headerContainerStyle}>
            <p
              className={styles.headerTextStyle}
            >{`${strings.yourOrders} (${orderedItemsData?.length})`}</p>
            <Image
              image={closeIcon}
              altText={strings.closeIcon}
              customImageContainerStyle={styles.imgStyle}
              onClick={() => setShowOrdersModal(false)}
            />
          </div>
          <div className={styles.cartDataBlockStyle}>
            {orderedItemsData.length > 0 ? (
              orderedItemsData?.map((item, index) => {
                return (
                  <ShoppingCartCard
                    key={index}
                    image={item?.image}
                    altText={strings.frameImage}
                    cardTitle={`Giclée Frame & Print (${item?.sizes.width} ${item.sizes.size_units} x ${item?.sizes.height} ${item.sizes.size_units})`}
                    paper={item.paper.title}
                    border={getBorderDescription(item?.paper_border)}
                    totalPrice={`$${(
                      item?.total_price * item?.quantity
                    ).toFixed(2)}`}
                    orderedQuantity={item?.quantity}
                  />
                );
              })
            ) : (
              <p className={styles.noDataFoundTextStyle}>
                {strings.noOrdersFound}
              </p>
            )}
          </div>
        </div>

        <div className={styles.btnContainerStyle}>
          <Button
            title={'Close'}
            customBtnContainerStyle={styles.checkOutBtnStyle}
            onClick={() => setShowOrdersModal(false)}
          />
        </div>
      </Modal>
    );
  };

  return (
    <div className={styles.headerSectionStyle}>
      {renderDeskTopSection()}
      {renderMobileSection()}
      {renderPriceModal()}
      {renderCartModal()}
      {renderOrdersModalSection()}
      {showLoader && <Loader />}
    </div>
  );
};

export default Header;
