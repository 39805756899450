import React, { useEffect, useState } from 'react';
import Capsule from 'components/common/capsule';
import { RadioButton } from 'components/common/radiobutton';
import { Button } from 'components/common/button';
import { Input } from 'components/common/input';
import RangeInput from 'components/common/rangeInput';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { tabsData } from 'constants';
import { dimensionsData } from 'constants';
import { borderPlacementData } from 'constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from 'constants';
import { useBeforeUnload } from 'hooks/usebeforeunload';
import { infoIcon } from 'resources/images';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { useStrings } from 'providers/stringsprovider';
import styles from './styles.module.css';

const SelectBorder = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const {
    productData,
    setProductData,
    onBeforeLoad,
    setOnBeforeUnLoad,
    width,
    height,
    aspectRatio,
  } = useAppDataContext();

  const { strings } = useStrings();

  // hooks
  useBeforeUnload(onBeforeLoad);

  const [borderErrorMsg, setBorderErrorMsg] = useState('');

  const {
    paper_border: { border_type, border_value, border_units, border_place },
  } = productData;
  const [selectedUnits, setSelectedUnits] = useState('');
  const [equalValues, setEqualValues] = useState(
    productData.paper_border.border_value
      ? productData.paper_border.border_value
      : 0
  );
  const [customValues, setCustomValues] = useState({
    top: productData.paper_border.border_value.top
      ? productData.paper_border.border_value.top
      : '',
    bottom: productData.paper_border.border_value.bottom
      ? productData.paper_border.border_value.bottom
      : '',
    right: productData.paper_border.border_value.right
      ? productData.paper_border.border_value.right
      : '',
    left: productData.paper_border.border_value.left
      ? productData.paper_border.border_value.left
      : '',
  });
  useEffect(() => {
    if (productData.paper_border.border_value) {
      if (productData.paper_border.border_type === 'Equal') {
        setCustomValues({
          top: '',
          bottom: '',
          right: '',
          left: '',
        });
      } else {
        setEqualValues(0);
      }
    }
  }, []);

  useEffect(() => {
    if (productData) {
      if (productData.sizes.size_units === 'mm') {
        setSelectedUnits('Millimetres');
        setProductData((prev) => ({
          ...prev,
          paper_border: {
            ...prev.paper_border,
            border_units: productData.sizes.size_units,
            border_value: border_type === 'Equal' ? equalValues : customValues,
          },
        }));
      } else {
        setSelectedUnits('Centimetres');
        setProductData((prev) => ({
          ...prev,
          paper_border: {
            ...prev.paper_border,
            border_units: productData.sizes.size_units,
            border_value: border_type === 'Equal' ? equalValues : customValues,
          },
        }));
      }
    }
  }, []);
  const handleMaxBorderValue = () => {
    let border =
      width - productData.sizes.width < height - productData.sizes.height
        ? width - productData.sizes.width
        : height - productData.sizes.height;

    if (productData.sizes.size_units === 'cm') {
      return (border > 50 ? 50 : border.toFixed(0)) / 2;
    } else if (productData.sizes.size_units === 'mm') {
      return (border > 500 ? 500 : border.toFixed(0)) / 2;
    }
  };
  const handleTabsChange = (item) => {
    setProductData((prev) => ({
      ...prev,
      paper_border: {
        ...prev.paper_border,
        border_type: item,
        border_value: item === 'Equal' ? equalValues : customValues,
      },
    }));
  };

  const handleBorderValues = (type, value) => {
    let border =
      width - productData.sizes.width < height - productData.sizes.height
        ? width - productData.sizes.width
        : height - productData.sizes.height;

    const parsedValue = () => {
      if (value === '') {
        return '';
      } else if (productData.sizes.size_units === 'cm') {
        return Math.ceil(
          (Number(value) * (border > 50 ? 50 : border.toFixed(0))) / 200
        );
      } else if (productData.sizes.size_units === 'mm') {
        return Math.ceil(
          (Number(value) * (border > 500 ? 500 : border.toFixed(0))) / 200
        );
      }
    };

    // if (
    //   border_type === 'Custom' &&
    //   value >
    //     (productData.sizes.size_units === 'cm'
    //       ? (border > 50 ? 50 : border.toFixed(0)) / 2
    //       : (border > 500 ? 500 : border.toFixed(0)) / 2)
    // ) {

    //   return;
    // }
    if (border_type === 'Equal') {
      setEqualValues(parsedValue());
      setProductData((prev) => ({
        ...prev,
        paper_border: {
          ...prev.paper_border,
          border_value: Math.floor(parsedValue().toFixed(0)),
        },
      }));
      if (parsedValue() > 0) {
        setOnBeforeUnLoad(true);
      }
    } else if (border_type === 'Custom') {
      setCustomValues((prev) => ({
        ...prev,
        [type]: value,
      }));
      setProductData((prev) => ({
        ...prev,
        paper_border: {
          ...prev.paper_border,
          border_value: {
            ...prev.paper_border.border_value,
            [type]: value,
          },
        },
      }));
      const { top, bottom, right, left } = {
        ...customValues,
        [type]: value,
      };
      if (top > 0 || bottom > 0 || right > 0 || left > 0) {
        setOnBeforeUnLoad(true);
      }
    }
  };

  const handleDisableFunction = () => {
    const { width, height, size_units } = productData.sizes;
    if (size_units === 'cm') {
      const highvalue = width > height ? width : height;
      const lowvalue = width < height ? width : height;
      if (highvalue > 158) {
        return true;
      } else if (highvalue > 108) {
        if (lowvalue > 108) {
          return true;
        } else {
          return false;
        }
      }
    } else if (size_units === 'mm') {
      const highvalue = width > height ? width : height;
      const lowvalue = width < height ? width : height;
      if (highvalue > 1580) {
        return true;
      } else if (highvalue > 1080) {
        if (lowvalue > 1080) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const handleRangeValueFunction = () => {
    let border =
      width - productData.sizes.width < height - productData.sizes.height
        ? width - productData.sizes.width
        : height - productData.sizes.height;

    if (productData.sizes.size_units === 'cm') {
      return Math.ceil(
        (
          (Number(border_value) / (border > 50 ? 50 : border.toFixed(0))) *
          400
        ).toFixed(0) / 2
      );
    } else if (productData.sizes.size_units === 'mm') {
      return Math.ceil(
        (
          (Number(border_value) / (border > 500 ? 500 : border.toFixed(0))) *
          400
        ).toFixed(0) / 2
      );
    }
  };

  useEffect(() => {
    borderErrorMessage();
  }, [productData]);

  const borderErrorMessage = () => {
    const { width, height, size_units } = productData.sizes;
    const highvalue = Math.max(width, height);
    const lowvalue = Math.min(width, height);

    if (size_units === 'cm') {
      if (highvalue > 158) {
        setBorderErrorMsg(
          'Maximum image size reached, space available for the border is less.'
        );
      } else if (highvalue > 108 && lowvalue > 108) {
        setBorderErrorMsg(
          'Maximum image size reached, space available for the border is less.'
        );
      } else {
        setBorderErrorMsg('');
      }
    } else if (size_units === 'mm') {
      if (highvalue > 1580) {
        setBorderErrorMsg(
          'Maximum image size reached, space available for the border is less.'
        );
      } else if (highvalue > 1080 && lowvalue > 1080) {
        setBorderErrorMsg(
          'Maximum image size reached, space available for the border is less.'
        );
      } else {
        setBorderErrorMsg('');
      }
    }
  };

  // console.log(productData.sizes, width, height, handleMaxBorderValue(), 'PPP');
  const renderTopSection = () => {
    return (
      <section className={styles.topStyle}>
        <div className={styles.tabsBlockStyle}>
          <div className={styles.tabsStyle}>
            {tabsData.map((item) => {
              return (
                <Capsule
                  key={item.value}
                  label={item?.name}
                  containerStyle={
                    border_type === item?.value
                      ? styles.activeCapsuleStyle
                      : styles.inActiveCapsuleStyle
                  }
                  labelStyle={
                    border_type === item?.value
                      ? styles.activeLabelText
                      : styles.inActiveLabelText
                  }
                  onClick={() => handleTabsChange(item?.value)}
                />
              );
            })}
          </div>

          <div className={styles.infoStyle}>
            <div className={styles.infoIconStyle}>
              <img
                src={infoIcon}
                alt="infoIcon"
                data-tooltip-id="my-tooltip-1"
                className={styles.imageStyle}
              />
            </div>
            <Tooltip
              id="my-tooltip-1"
              place="bottom"
              content={strings.selectBorderInfoDesc}
              style={{ width: '300px' }}
            />
          </div>
        </div>
        <p
          className={borderErrorMsg ? styles.borderMsgStyle : styles.descpStyle}
        >
          {borderErrorMsg ? borderErrorMsg : strings.selectBorderInfoDesc}
        </p>
        <div className={styles.borderStyle}>
          {border_type === 'Equal' ? (
            <div className={styles.borderTopStyle}>
              <div className={styles.borderTitleStyle}>
                <p className={styles.borderLabelStyle}>Border Size</p>
                <p
                  className={styles.valueStyle}
                >{`${border_value} ${border_units}`}</p>
              </div>
              <RangeInput
                minValue={0}
                maxValue={100}
                disabled={handleDisableFunction()}
                value={handleRangeValueFunction()}
                onChange={(e) => {
                  handleBorderValues(null, e.target.value);
                }}
              />
            </div>
          ) : (
            <div className={styles.inputsSecStyle}>
              <Input
                label="Top"
                min={0}
                max={handleMaxBorderValue()}
                type="number"
                placeholder="0cm"
                value={productData.paper_border.border_value.top}
                onChange={(e) => {
                  if (e.target.value <= handleMaxBorderValue()) {
                    handleBorderValues('top', e.target.value);
                  }
                }}
                customLabelStyle={styles.inputLabelStyle}
              />
              <Input
                label="Bottom"
                min={0}
                max={handleMaxBorderValue()}
                type="number"
                placeholder="0cm"
                value={productData.paper_border.border_value.bottom}
                onChange={(e) => {
                  if (e.target.value <= handleMaxBorderValue()) {
                    handleBorderValues('bottom', e.target.value);
                  }
                }}
                customLabelStyle={styles.inputLabelStyle}
              />
              <Input
                label="Right"
                min={0}
                max={handleMaxBorderValue()}
                type="number"
                placeholder="0cm"
                value={productData.paper_border.border_value.right}
                onChange={(e) => {
                  if (e.target.value <= handleMaxBorderValue()) {
                    handleBorderValues('right', e.target.value);
                  }
                }}
                customLabelStyle={styles.inputLabelStyle}
              />
              <Input
                label="Left"
                min={0}
                max={handleMaxBorderValue()}
                type="number"
                placeholder="0cm"
                value={productData.paper_border.border_value.left}
                onChange={(e) => {
                  if (e.target.value <= handleMaxBorderValue()) {
                    handleBorderValues('left', e.target.value);
                  }
                }}
                customLabelStyle={styles.inputLabelStyle}
              />
            </div>
          )}
          <div className={styles.borderBottomStyle}>
            <div className={styles.InAndOutSideStyle}>
              {borderPlacementData?.map((unit, index) => (
                <RadioButton
                  key={index}
                  label={unit?.name}
                  selected={border_place}
                  onClick={() =>
                    setProductData((prev) => ({
                      ...prev,
                      paper_border: {
                        ...prev.paper_border,
                        border_place: unit?.value,
                      },
                    }))
                  }
                  customRadioBtnStyle={styles.radioBtnStyle}
                  customLabelStyle={styles.radioLabelStyle}
                />
              ))}
            </div>
            <div className={styles.dimensionStyle}>
              {dimensionsData?.map((unit, index) => (
                <RadioButton
                  key={index}
                  label={unit.name}
                  selected={selectedUnits}
                  // onClick={() => {
                  //   setSelectedUnits(unit.name);
                  //   setProductData((prev) => ({
                  //     ...prev,
                  //     paper_border: {
                  //       ...prev.paper_border,
                  //       border_units: unit.value,
                  //     },
                  //   }));
                  // }}
                  customLabelStyle={styles.radioLabelStyle}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderBottomSection = () => {
    return (
      <section className={styles.bottomStyle}>
        <Button
          title="Previous"
          customBtnTitleStyle={styles.prevBtnTitleStyle}
          customBtnContainerStyle={styles.prevBtnViewStyle}
          onClick={() => navigate(routeNames.imageSizeAndCrop)}
        />

        <Button
          title="Apply Changes"
          customBtnContainerStyle={styles.applyBtnStyle}
          customBtnTitleStyle={styles.applyTitleStyle}
          onClick={() => navigate(routeNames.choosePaper)}
        />
      </section>
    );
  };

  return (
    <main className={styles.selectBorderStyle}>
      {renderTopSection()}
      {renderBottomSection()}
    </main>
  );
};

export default SelectBorder;
