import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const RangeInput = (props) => {
  const {
    minValue,
    maxValue,
    value,
    step,
    disabled,
    onChange = () => {},
    customRangeInputStyle,
  } = props;

  const normalizedValue = ((value - minValue) / (maxValue - minValue)) * 100;

  return (
    <input
      type="range"
      style={{ '--slider-value': `${normalizedValue}%` }}
      min={minValue}
      max={maxValue}
      step={step ? step : 1}
      disabled={disabled}
      value={value}
      className={classNames(
        styles.rangeInputStyle,
        customRangeInputStyle,
        disabled && styles.disabledRangeStyle
      )}
      onChange={onChange}
    />
  );
};

export default RangeInput;

RangeInput.propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  step: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  customRangeInputStyle: PropTypes.string,
};
