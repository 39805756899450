import React, { useEffect } from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';

const Modal = (props) => {
  const {
    isOpen,
    onClose = () => {},
    children,
    customModalContainerStyle,
    customModalContentStyle,
  } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;
  return (
    <div
      className={classNames(
        styles.modalContainerStyle,
        customModalContainerStyle
      )}
      onClick={onClose}
    >
      <div
        className={classNames(
          styles.modalContentStyle,
          customModalContentStyle
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  isOpen: propTypes.bool,
  onClose: propTypes.func,
  children: propTypes.node,
  customModalContainerStyle: propTypes.string,
  customModalContentStyle: propTypes.string,
};
