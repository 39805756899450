import React, { createContext, useEffect, useMemo, useState } from 'react';
import { NetWorkInfo } from 'components/common/networkinfo';

const NetworkStatusContext = createContext();

const NetWorkProvider = (props) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const handleNetwork = () => {
      setIsOnline(navigator.onLine);
      console.log(navigator.onLine, 'navigator.onLine');
      if (navigator.onLine) {
        setShowMessage(true);

        const timer = setTimeout(() => {
          setShowMessage(false);
        }, 3000);
        return () => {
          clearTimeout(timer);
        };
      } else {
        setShowMessage(true);
      }
    };

    window.addEventListener('online', handleNetwork);
    window.addEventListener('offline', handleNetwork);
    return () => {
      window.removeEventListener('online', handleNetwork);
      window.removeEventListener('offline', handleNetwork);
    };
  }, []);

  const networkStatusValue = useMemo(
    () => ({
      isOnline,
      showMessage,
    }),
    [isOnline, showMessage]
  );

  return (
    <NetworkStatusContext.Provider value={networkStatusValue}>
      {props.children}
      <NetWorkInfo />
    </NetworkStatusContext.Provider>
  );
};

export { NetworkStatusContext, NetWorkProvider };
