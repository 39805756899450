import { useContext } from 'react';
import { CartDataContext } from 'providers/cartdataprovider';

export const useCartDataContext = () => {
  const context = useContext(CartDataContext);

  if (!context)
    throw new Error(
      'useCartDataContext context must be use inside CartDataProvider'
    );

  return context;
};
