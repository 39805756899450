import React, { useEffect, useState } from 'react';
import { Image } from 'components/common/image';
import { closeIcon, paperOne, tickLightIcon } from 'resources/images';
import classNames from 'classnames';
import { Button } from 'components/common/button';
import Modal from 'components/common/modal';
import { useNavigate } from 'react-router-dom';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { useStrings } from 'providers/stringsprovider';
import { useBeforeUnload } from 'hooks/usebeforeunload';
import { useCartDataContext } from 'hooks/usecartdatacontext';
import styles from './styles.module.css';
import { routeNames } from 'constants';

const ChoosePaper = (props) => {
  const navigate = useNavigate();

  const {
    productData,
    setProductData,
    allPapers,
    getPapersApiCall,
    onBeforeLoad,
    setOnBeforeUnLoad,
  } = useAppDataContext();

  const { uploadImage } = useCartDataContext();

  const { strings } = useStrings();

  // hooks
  useBeforeUnload(onBeforeLoad);

  const [selectedPaper, setSelectedPaper] = useState(null);
  const [paperData, setPaperData] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    getPapersApiCall();
  }, []);

  const renderPapers = () => {
    return (
      <section className={styles.papersViewStyle}>
        {allPapers?.map((paper, index) => (
          <div
            key={index}
            onClick={() => {
              if (selectedPaper === paper?._id) {
                setSelectedPaper('');
                setProductData((prev) => ({
                  ...prev,
                  paper: '',
                }));
              } else {
                setSelectedPaper(paper?._id);
                setProductData((prev) => ({
                  ...prev,
                  paper: paper?._id,
                }));
                setOnBeforeUnLoad(true);
              }
            }}
            className={styles.paperWrapperStyle}
          >
            {/* checkbox */}
            <div
              className={classNames(
                styles.checkboxViewStyle,
                (selectedPaper === paper?._id ||
                  productData.paper === paper._id) &&
                  styles.activeCheckboxViewStyle
              )}
            >
              {(selectedPaper === paper?._id ||
                productData.paper === paper._id) && (
                <Image
                  image={tickLightIcon}
                  altText={strings.tickLightIcon}
                  customImageStyle={styles.imgStyle}
                  customImageContainerStyles={styles.checkIconViewStyle}
                />
              )}
            </div>
            {/* content  */}
            <div className={styles.contentViewStyle}>
              <p className={styles.contentTitleTextStyle}>{paper?.title}</p>
              <p className={styles.contentDescTextStyle}>
                {paper?.description}
              </p>
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShowModal(true);
                  setPaperData(paper);
                }}
                className={styles.moreTextStyle}
              >
                more
              </p>
            </div>
            {/* paper img  */}
            <Image
              image={paper.image}
              customImageContainerStyle={styles.imgContainerStyle}
            />
          </div>
        ))}
      </section>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        isOpen={isShowModal}
        customModalContainerStyle={styles.modalContainerStyle}
        // onClose={() => setIsShowModal(false)}
        customModalContentStyle={styles.modalViewStyle}
      >
        <div className={styles.modalSubViewStyle}>
          <div className={styles.modalHeaderViewStyle}>
            <p className={styles.modalHeaderTextStyle}>{paperData?.title}</p>
            <Image
              image={closeIcon}
              onClick={() => setIsShowModal(false)}
              customImageContainerStyle={styles.closeIconViewStyle}
            />
          </div>
          <div className={styles.modalContentViewStyle}>
            <Image
              image={paperData?.image}
              customImageContainerStyle={styles.paperImgViewStyle}
            />
            <p className={styles.modalContentTextStyle}>
              {paperData?.description}
            </p>
          </div>
          {/* <p className={styles.viewGalleryTextStyle}>View gallery</p> */}
        </div>
      </Modal>
    );
  };

  const renderBtnSection = () => {
    return (
      <div className={styles.bottomBtnBlockStyle}>
        <Button
          title="Previous"
          customBtnTitleStyle={styles.prevBtnTitleStyle}
          customBtnContainerStyle={styles.prevBtnViewStyle}
          onClick={() => navigate(routeNames.selectBorder)}
        />

        <Button
          title="Add to Cart"
          disabled={productData.paper ? false : true}
          customBtnContainerStyle={styles.btnViewStyle}
          onClick={() => uploadImage()}
          customBtnTitleStyle={styles.addToCarBtnTitleStyle}
        />
      </div>
    );
  };

  return (
    <main className={styles.containerStyle}>
      <div className={styles.subContainerStyle}>
        <p className={styles.noteTextStyle}>{strings.choosePaperInfo}</p>
        {renderPapers()}
      </div>
      {renderBtnSection()}
      {renderModal()}
    </main>
  );
};

export default ChoosePaper;
