import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import { Image } from '../image';
import styles from './styles.module.css';
import { tickLightIcon } from 'resources/images';

const RadioButton = (props) => {
  const {
    label,
    selected,
    onClick = () => {},
    checkedImageAltText,
    customRadioBtnContainerStyle,
    customRadioBtnStyle,
    customLabelStyle,
  } = props;

  // function:

  return (
    <div
      className={classNames(
        styles.radioBtnContainerStyle,
        customRadioBtnContainerStyle
      )}
      onClick={onClick}
    >
      <div
        className={classNames(
          styles.radioWrapperStyle,
          selected === label && styles.activeViewStyle,
          customRadioBtnStyle
        )}
      >
        {selected === label && (
          <Image
            image={tickLightIcon}
            altText={checkedImageAltText}
            customImageContainerStyle={styles.checkIconStyle}
            customImageStyle={styles.imgStyle}
          />
        )}
      </div>
      <p
        className={classNames(
          styles.labelTextStyle,
          selected === label && styles.activeLabelStyle,
          customLabelStyle
        )}
      >
        {label}
      </p>
    </div>
  );
};

export { RadioButton };
RadioButton.propTypes = {
  label: propTypes.string,
  selected: propTypes.bool,
  onClick: propTypes.func,
  checkedImageAltText: propTypes.string,
  customRadioBtnStyle: propTypes.string,
  customLabelStyle: propTypes.string,
};
