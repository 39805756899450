import React from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';
import { Image } from '../image';
import styles from './styles.module.css';

const Button = (props) => {
  const {
    title,
    image,
    altText,
    onClick = () => {},
    loader,
    disabled,
    customBtnTitleStyle,
    customBtnContainerStyle,
    customBtnImageContainerStyle,
    customBtnImageStyle,
  } = props;

  return (
    <button
      className={classNames(
        styles.btnContainerStyle,
        disabled && styles.btnDisabledStyle,
        customBtnContainerStyle
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {loader ? (
        <div className={styles.loaderStyle}></div>
      ) : (
        title && (
          <p className={classNames(styles.titleStyle, customBtnTitleStyle)}>
            {title}
          </p>
        )
      )}

      {image && !loader && (
        <Image
          image={image}
          altText={altText}
          customImageContainerStyles={classNames(
            styles.btnImageContainerStyle,
            customBtnImageContainerStyle
          )}
          customImageStyles={classNames(
            styles.btnImageStyle,
            customBtnImageStyle
          )}
        />
      )}
    </button>
  );
};

Button.propTypes = {
  title: propTypes.string,
  image: propTypes.any,
  altText: propTypes.string,
  onClick: propTypes.func,
  loader: propTypes.bool,
  disabled: propTypes.bool,
  customBtnTitleStyle: propTypes.string,
  customBtnContainerStyle: propTypes.string,
  customBtnImageContainerStyle: propTypes.string,
  customBtnImageStyle: propTypes.string,
};

export { Button };
