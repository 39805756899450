import React, { Suspense, lazy } from 'react';
import { routeNames } from 'constants';
import Layout from 'layout';
import { useRoutes } from 'react-router-dom';
import GuestGuard from 'guards/guestguard';
import AuthGuard from 'guards/authguard';

const Home = lazy(() => import('pages/home'));
const UploadOrChangeImage = lazy(() => import('pages/upload-or-changeImage'));
const ImageSizeAndCrop = lazy(() => import('pages/imagesize-or-crop'));
const SelectBorder = lazy(() => import('pages/select-border'));
const ChoosePaper = lazy(() => import('pages/choose-paper'));
const ChooseFrame = lazy(() => import('pages/choose-frame'));
const CheckOut = lazy(() => import('pages/checkout'));

const Signup = lazy(() => import('pages/auth/user/signup'));
const Login = lazy(() => import('pages/auth/user/login'));
const ForgotPassword = lazy(() => import('pages/auth/user/forgotpassword'));
const ResetPassword = lazy(() => import('pages/auth/user/resetpassword'));
const EditProfile = lazy(() => import('pages/auth/user/edit'));

const AdminLogin = lazy(() => import('pages/auth/admin/login'));
const AdminDashBoard = lazy(() => import('pages/admin/dashboard'));

// Papers
const ListOfPapers = lazy(() => import('pages/admin/paper/listofpapers'));
const CreatePaper = lazy(() => import('pages/admin/paper/createpaper'));
const EditPaper = lazy(() => import('pages/admin/paper/editPaper'));

// Price
const AddPrice = lazy(() => import('pages/admin/updateprice'));

// Orders
const AllOrders = lazy(() => import('pages/admin/all-orders'));

const NotFoundPage = lazy(() => import('pages/notfoundpage'));

const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: '',
      element: <Layout />,
      children: [
        {
          path: routeNames.home,
          element: <Home />,
        },
        {
          path: routeNames.uploadOrChangeImage,
          element: <UploadOrChangeImage />,
        },
        {
          path: routeNames.imageSizeAndCrop,
          element: <ImageSizeAndCrop />,
        },
        {
          path: routeNames.selectBorder,
          element: <SelectBorder />,
        },
        {
          path: routeNames.choosePaper,
          element: <ChoosePaper />,
        },
        {
          path: routeNames.chooseFrame,
          element: <ChooseFrame />,
        },
      ],
    },

    {
      path: routeNames.checkout,
      element: <CheckOut />,
    },
    {
      path: routeNames.signup,
      element: (
        <GuestGuard>
          <Signup />
        </GuestGuard>
      ),
    },
    {
      path: routeNames.login,
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: routeNames.forgotPassword,
      element: (
        <GuestGuard>
          <ForgotPassword />
        </GuestGuard>
      ),
    },
    {
      path: `${routeNames.resetPassword}/:id`,
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },
    {
      path: routeNames.editProfile,
      element: (
        <AuthGuard>
          <EditProfile />
        </AuthGuard>
      ),
    },

    {
      path: routeNames.adminLogin,
      element: <AdminLogin />,
    },

    {
      path: routeNames.adminDashboard,
      element: <AdminDashBoard />,
    },

    {
      path: routeNames.createPaper,
      element: <CreatePaper />,
    },

    {
      path: routeNames.listOfPapers,
      element: <ListOfPapers />,
    },

    {
      path: routeNames.editPaper,
      element: <EditPaper />,
    },

    {
      path: routeNames.addPrice,
      element: <AddPrice />,
    },

    {
      path: routeNames.allOrders,
      element: <AllOrders />,
    },

    {
      path: '*',
      element: <NotFoundPage />,
    },
  ]);

  return <Suspense fallback={<>Loading...</>}>{routes}</Suspense>;
};

export { AppRoutes };
