import React from 'react';
import styles from './styles.module.css';
import propTypes from 'prop-types';
import Modal from 'components/common/modal';
import { Button } from 'components/common/button';
import classNames from 'classnames';

const AlertModal = (props) => {
  const {
    descText,
    showAlert,
    onClickYes = () => {},
    onClickApply = () => {},
    btnCustomContainerStyle,
    descTextCustomStyle,
  } = props;
  return (
    <Modal
      isOpen={showAlert}
      customModalContentStyle={styles.alertModalContainerStyle}
    >
      <p className={classNames(styles.descTextStyle, descTextCustomStyle)}>
        {descText}
      </p>
      <div
        className={classNames(
          styles.btnContainerStyle,
          btnCustomContainerStyle
        )}
      >
        <Button
          title={'Apply changes'}
          onClick={onClickApply}
          customBtnContainerStyle={styles.applyBtnStyle}
          customBtnTitleStyle={styles.applyBtnTitleStyle}
        />
        <Button title={'Yes'} onClick={onClickYes} />
      </div>
    </Modal>
  );
};

export default AlertModal;
AlertModal.propTypes = {
  descText: propTypes.string,
  showAlert: propTypes.bool,
  onClickYes: propTypes.func,
  onClickApply: propTypes.func,
  descTextCustomStyle: propTypes.string,
  btnCustomContainerStyle: propTypes.string,
};
