import React, { createContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  /*show the success message*/
  const showToast = {
    success: (message) => {
      toast.success(
        <>
          <p>{message}</p>
        </>
      );
    },

    /*show the error message*/
    error: (message) => {
      toast.error(
        <>
          <p>{message}</p>
        </>
      );
    },
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer position="top-right" />
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
