import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';

const Loader = (props) => {
  const { customLoaderContainerStyle, customLoaderStyle } = props;
  return (
    <div
      className={classNames(
        styles.loaderContainerStyle,
        customLoaderContainerStyle
      )}
    >
      <div className={classNames(styles.loaderStyle, customLoaderStyle)}></div>
    </div>
  );
};

export { Loader };
