import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const adminLoginApi = async (data) =>
  Promise.resolve(axios.post(endPoints.adminLogin, data));

export const createPaperApi = async (data) =>
  Promise.resolve(axios.post(endPoints.createPaper, data));

export const updatePriceApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updatePrice, data));

export const deletePaperApi = async (id) =>
  Promise.resolve(axios.delete(endPoints.deletePaper.replace('{id}', id)));

export const updatePaperDataApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updatePaper, data));

export const getAllUserOrdersApi = async (id) =>
  Promise.resolve(axios.get(endPoints.allOrders.replace('{id}', id)));
