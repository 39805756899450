import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { addToCartApi } from 'networking/apis/orders';
import { getPapersApi, getPricesApi } from 'networking/apis/common';
import { routeNames } from 'constants';
import { useLocation } from 'react-router-dom';

const AppDataContext = createContext();

const AppDataProvider = (props) => {
  const location = useLocation();
  // states:
  const [productData, setProductData] = useState({
    user_id: '',
    image: '',
    edited_image: '',
    sizes: {
      size_type: '',
      size_units: 'cm',
      width: 0,
      height: 0,
      aspect_ratio: '',
    },
    paper_border: {
      border_type: 'Equal',
      border_place: 'Outside',
      border_units: '',
      border_value: '',
    },
    paper: '',
    quantity: '1',
    total_price: '',
  });

  const [pricesData, setPricesData] = useState(null);

  const [aspectRatio, setAspectRatio] = useState();
  const [sizePercentage, setSizePercentage] = useState(60);
  const [isCropperVisible, setIsCropperVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [onBeforeLoad, setOnBeforeUnLoad] = useState(false);
  const [allPapers, setAllPapers] = useState([]);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const cropperRef = useRef();
  const divRef = useRef();
  const [widthImg, setWidthImg] = useState(0);
  const [heightImg, setHeightImg] = useState(0);
  const [scaleValue, setScaleValue] = useState(1);
  const [lockWithAndHeight, setLockWithAndHeight] = useState(true);
  const [selectedRatio, setSelectedRatio] = useState('3:2');

  useEffect(() => {
    if (divRef.current) {
      const { width, height } = divRef.current.getBoundingClientRect();
      setWidthImg(width);
      setHeightImg(height);
    }
  }, [divRef.current, location.pathname === routeNames.selectBorder]);

  // reset product data
  const resetProductData = () => {
    setProductData((prevData) => ({
      ...prevData,
      image: '',
      edited_image: '',
      sizes: {
        size_type: '',
        size_units: 'cm',
        width: 0,
        height: 0,
        aspect_ratio: '',
      },
      paper_border: {
        border_type: 'Equal',
        border_place: 'Outside',
        border_units: '',
        border_value: '',
      },
      paper: '',
      quantity: '1',
      total_price: '',
    }));
    setImageUrl(null);
  };

  useEffect(() => {
    getPricesOfImage();
  }, []);

  useEffect(() => {
    getPapersApiCall();
  }, []);

  //get papers
  const getPapersApiCall = async () => {
    try {
      setShowLoader(true);
      const res = await getPapersApi();
      if (res.data.type === 'success') {
        setAllPapers(res.data.data);
        setShowLoader(false);
      }
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };
  // get prices for image

  const getPricesOfImage = async () => {
    try {
      const response = await getPricesApi();
      if (response.data.type === 'success') {
        setPricesData(response.data.data[0]);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleCrop = async () => {
    setShowLoader(true);
    try {
      const cropRef = cropperRef.current.getCanvas();
      const isCropperUrl = cropRef.toDataURL();
      if (isCropperUrl) {
        const res = await fetch(isCropperUrl);
        const blob = await res.blob();
        setProductData((prev) => ({
          ...prev,
          edited_image: new File([blob], productData.image.name, {
            type: blob.type,
          }),
        }));
        setImageUrl(
          URL.createObjectURL(
            new File([blob], productData.image.name, {
              type: blob.type,
            })
          )
        );
        setIsCropperVisible(false);
        setShowLoader(false);
      }
    } catch (error) {
      console.error('Error cropping the image:', error);
    } finally {
      setShowLoader(false);
    }
  };
  const memoizedValue = useMemo(
    () => ({
      // values:
      productData,
      imageUrl,
      aspectRatio,
      isCropperVisible,
      showLoader,
      sizePercentage,
      onBeforeLoad,
      pricesData,
      allPapers,
      width,
      height,
      widthImg,
      heightImg,
      divRef,
      scaleValue,
      selectedRatio,
      lockWithAndHeight,

      // functions:
      setProductData,
      setImageUrl,
      setAspectRatio,
      setIsCropperVisible,
      setShowLoader,
      setSizePercentage,
      setOnBeforeUnLoad,
      setPricesData,
      getPapersApiCall,
      resetProductData,
      handleCrop,
      cropperRef,
      setWidth,
      setHeight,
      setHeightImg,
      setWidthImg,
      setScaleValue,
      getPricesOfImage,
      setSelectedRatio,
      setLockWithAndHeight,
    }),

    [
      // values:
      productData,
      imageUrl,
      aspectRatio,
      isCropperVisible,
      showLoader,
      sizePercentage,
      onBeforeLoad,
      pricesData,
      allPapers,
      width,
      height,
      widthImg,
      heightImg,
      divRef,
      scaleValue,
      selectedRatio,
      lockWithAndHeight,

      // functions:
      setProductData,
      setImageUrl,
      setAspectRatio,
      setIsCropperVisible,
      setShowLoader,
      setSizePercentage,
      setOnBeforeUnLoad,
      setPricesData,
      getPapersApiCall,
      resetProductData,
      handleCrop,
      cropperRef,
      setWidth,
      setHeight,
      setHeightImg,
      setWidthImg,
      setScaleValue,
      getPricesOfImage,
      setSelectedRatio,
      setLockWithAndHeight,
    ]
  );

  return (
    <AppDataContext.Provider value={memoizedValue}>
      {props.children}
    </AppDataContext.Provider>
  );
};

export { AppDataContext, AppDataProvider };
