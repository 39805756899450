import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useAuthContext } from 'hooks/useauthcontext';
import { useAppDataContext } from 'hooks/useappdatacontext';
import {
  addToCartApi,
  deleteCartApi,
  getAllCartItemsApi,
  getAllOrdersApi,
  purchaseApi,
  updateCartApi,
} from 'networking/apis/orders';
import { uploadImageApi } from 'networking/apis/common';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'constants';
import { useToastContext } from 'hooks/usetoastcontext';
import { useStrings } from './stringsprovider';
import { ratiosData } from 'constants';

const CartDataContext = createContext();

const CartDataProvider = (props) => {
  const navigate = useNavigate();

  const { userData } = useAuthContext();
  const {
    productData,
    aspectRatio,
    allPapers,
    pricesData,
    resetProductData,
    setShowLoader,
    selectedRatio,
    width,
    height,
  } = useAppDataContext();

  const { showToast } = useToastContext();
  const { strings } = useStrings();

  const [cartData, setCartData] = useState([]);
  const [orderedItemsData, setOrderedItemsData] = useState([]);
  const [cartModal, setCartModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showOrdersModal, setShowOrdersModal] = useState(false);

  const paperData = allPapers?.filter((item) => productData.paper === item._id);
  const paperPrice =
    paperData &&
    paperData[0]?.price?.filter(
      (item) => productData.sizes.size_units === item.units
    );

  useEffect(() => {
    if (userData?._id) {
      getAllCartItems(userData?._id);
    }
  }, [userData]);

  useEffect(() => {
    if (userData?._id) {
      getOrderedItems(userData?._id);
    }
  }, [userData]);

  // price caluculation

  const priceCaluculation = () => {
    let totalPrice = 0;

    if (productData && pricesData) {
      // const {
      //   sizes: { size_type, size_units, width, height },
      //   paper_border,
      // } = productData;

      if (productData?.sizes?.size_type !== 'Standard') {
        if (productData?.sizes?.size_units === 'cm') {
          totalPrice +=
            productData?.sizes?.width *
            productData?.sizes?.height *
            pricesData?.price_for_sqcm;
        } else if (productData?.sizes?.size_units === 'mm') {
          const widthInCm = productData?.sizes?.width / 10;
          const heightInCm = productData?.sizes?.height / 10;
          totalPrice += widthInCm * heightInCm * pricesData?.price_for_sqcm;
        }
      } else {
        const sizeData =
          ratiosData?.find((ratio) => ratio[selectedRatio])[selectedRatio] ||
          [];
        const matchingSize = sizeData.find((item) => {
          const itemWidth =
            productData?.sizes?.size_units === 'cm'
              ? item.widthStandard
              : item.widthStandard * 10;
          const itemHeight =
            productData?.sizes?.size_units === 'cm'
              ? item.heightStandard
              : item.heightStandard * 10;

          if (width > height) {
            return (
              Number(itemWidth) === Number(productData?.sizes?.width) &&
              Number(itemHeight) === Number(productData?.sizes?.height)
            );
          } else {
            return (
              Number(itemHeight) === Number(productData?.sizes?.width) &&
              Number(itemWidth) === Number(productData?.sizes?.height)
            );
          }
        });

        totalPrice += matchingSize?.price || 0;
      }

      // Add the border price based on the size units
      totalPrice += getBorderPrice();
      if (paperPrice) {
        totalPrice += Number(paperPrice[0]?.price);
      }
    }

    return Number(totalPrice.toFixed(2));
  };

  // get border price

  const getBorderPrice = () => {
    if (productData.paper_border.border_type === 'Equal') {
      return (
        ((Number(productData.sizes.width) +
          2 * Number(productData.paper_border.border_value)) *
          (Number(productData.sizes.height) +
            2 * Number(productData.paper_border.border_value)) -
          Number(productData.sizes.width) * Number(productData.sizes.height)) *
        (productData.sizes.size_units === 'cm'
          ? Number(pricesData?.price_for_sqcm)
          : Number(pricesData?.price_for_sqcm / 10))
      );
    } else {
      return (
        ((Number(productData.sizes.width) +
          (Number(productData.paper_border.border_value.left) +
            Number(productData.paper_border.border_value.right))) *
          (Number(productData.sizes.height) +
            (Number(productData.paper_border.border_value.top) +
              Number(productData.paper_border.border_value.bottom))) -
          Number(productData.sizes.width) * Number(productData.sizes.height)) *
        (productData.sizes.size_units === 'cm'
          ? Number(pricesData?.price_for_sqcm)
          : Number(pricesData?.price_for_sqcm / 10))
      );
    }
  };

  // upload image

  const uploadImage = async () => {
    try {
      setShowLoader(true);

      const image = productData.image;
      const originalImgFormData = new FormData();
      originalImgFormData.append('image', image);

      let editedImgFormData;
      if (productData.edited_image) {
        const editedImage = productData.edited_image;
        originalImgFormData.append('edited_image', editedImage);
      }

      if (!productData.paper) {
        showToast.error(strings.selectPaperError);
      } else if (!userData) {
        navigate(routeNames.login);
      } else {
        const response = await uploadImageApi(
          originalImgFormData,
          editedImgFormData
        );

        if (response.data.type === 'success') {
          setShowLoader(false);
          addProductToCart(response.data.data);
          navigate(routeNames.home);
        }
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setShowLoader(false);
    }
  };

  //add product to cart

  const addProductToCart = async (imagesData) => {
    try {
      setShowLoader(true);
      const {
        sizes: { aspect_ratio, size_units, width, height, ...restSizes },
        paper_border,
        ...restProductData
      } = productData;

      // Update the paper_border object if border_type is "Equal"
      let updatedPaperBorder = paper_border;
      if (paper_border.border_type === 'Equal') {
        const equalBorderValue = paper_border.border_value || 0;
        updatedPaperBorder = {
          ...paper_border,
          border_value: {
            top: equalBorderValue,
            bottom: equalBorderValue,
            right: equalBorderValue,
            left: equalBorderValue,
          },
        };
      } else {
        // Ensure default values for non-equal border types
        updatedPaperBorder = {
          ...paper_border,
          border_value: {
            top: paper_border.border_value?.top || 0,
            bottom: paper_border.border_value?.bottom || 0,
            right: paper_border.border_value?.right || 0,
            left: paper_border.border_value?.left || 0,
          },
        };
      }

      const updatedProductData = {
        ...restProductData,
        user_id: userData._id,
        image: imagesData.image,
        edited_image: imagesData.edited_image,
        sizes: {
          ...restSizes,
          width,
          height,
          size_units,
          ...(productData.sizes.size_type === 'Standard' && {
            aspect_ratio: aspectRatio,
          }),
        },
        paper_border: updatedPaperBorder,
        total_price: priceCaluculation(),
      };

      const response = await addToCartApi(updatedProductData);

      if (response.data.type === 'success') {
        setShowLoader(false);
        setShowPriceModal(false);
        // resetProductData();
        getAllCartItems(userData?._id);
        showToast.success(response.data.message);
      } else {
        setShowLoader(false);
        showToast.error(response.data.message);
      }
    } catch (error) {
      setShowLoader(false);
      console.log('error', error);
    }
  };

  // get all cart items
  const getAllCartItems = async (id) => {
    try {
      setShowLoader(true);
      const response = await getAllCartItemsApi(id);
      if (response.data.type === 'success') {
        setCartData(response.data.data);
      }
    } catch (error) {
      console.log('Error fetching cart items:', error);
    } finally {
      setShowLoader(false);
    }
  };

  // update cart item
  const updateCartItem = async (id, quantity) => {
    try {
      setShowLoader(true);
      const data = { _id: id, quantity: quantity };
      const response = await updateCartApi(data);

      if (response.data.type === 'success') {
        getAllCartItems(userData?._id);
      }
    } catch (error) {
      console.log('Error updating cart item:', error);
    } finally {
      setShowLoader(false);
    }
  };

  // delete cart item
  const deleteCartItem = async (id) => {
    try {
      setShowLoader(true);
      const response = await deleteCartApi(id);
      if (response.data.type === 'success') {
        showToast.success(response?.data?.message);
        getAllCartItems(userData?._id);
        setCartModal(false);
      }
    } catch (error) {
      console.log('Error deleting cart item:', error);
    } finally {
      setShowLoader(false);
    }
  };

  // purchase product

  const purchaseProduct = async (data) => {
    try {
      setShowLoader(true);
      const response = await purchaseApi(data);
      if (response.data.type === 'success') {
        setShowLoader(false);
        navigate(routeNames.home);
        showToast.success(response.data.message);
        setCartModal(false);
        getAllCartItems(userData?._id);
        getOrderedItems(userData?._id);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      console.log('error', error);
    }
  };

  // get ordered items

  const getOrderedItems = async (id) => {
    try {
      setShowLoader(true);
      const response = await getAllOrdersApi(id);
      if (response.data.type === 'success') {
        const orderItems = response.data.data.flatMap(
          (order) => order?.order_items
        );
        setOrderedItemsData(orderItems);
      }
    } catch (error) {
      console.log('Error fetching cart items:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const memoizedValue = useMemo(
    () => ({
      // values:
      cartData,
      cartModal,
      showPriceModal,
      showOrdersModal,
      orderedItemsData,

      // functions:
      setCartData,
      getAllCartItems,
      updateCartItem,
      deleteCartItem,
      setCartModal,
      uploadImage,
      priceCaluculation,
      getBorderPrice,
      setShowPriceModal,
      purchaseProduct,
      setShowOrdersModal,
      setOrderedItemsData,
    }),

    [
      // values:
      cartData,
      cartModal,
      showPriceModal,
      showOrdersModal,
      orderedItemsData,

      // functions:
      setCartData,
      getAllCartItems,
      updateCartItem,
      deleteCartItem,
      setCartModal,
      uploadImage,
      priceCaluculation,
      getBorderPrice,
      setShowPriceModal,
      purchaseProduct,
      setShowOrdersModal,
      setOrderedItemsData,
    ]
  );

  return (
    <CartDataContext.Provider value={memoizedValue}>
      {props.children}
    </CartDataContext.Provider>
  );
};

export { CartDataContext, CartDataProvider };
