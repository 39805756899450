import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const uploadImageApi = async (data) =>
  Promise.resolve(axios.post(endPoints.uploadImage, data));

export const getPricesApi = async () =>
  Promise.resolve(axios.get(endPoints.getPrices));

export const getPapersApi = async () =>
  Promise.resolve(axios.get(endPoints.getPapers));

export const getFramesApi = async () =>
  Promise.resolve(axios.get(endPoints.getFrames));

export const getCountriesDataApi = async () =>
  Promise.resolve(axios.get(endPoints.countriesData));
