import React from 'react';
import propTypes from 'prop-types';
import { closeIcon } from 'resources/images';
import { Image } from 'components/common/image';
import { Button } from 'components/common/button';
import { useStrings } from 'providers/stringsprovider';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { formatCurrency } from 'functions';
import { useCartDataContext } from 'hooks/usecartdatacontext';
import { ratiosData } from 'constants';
import styles from './styles.module.css';

const ProductSummary = (props) => {
  const { strings } = useStrings();
  const {
    productData,
    pricesData,
    allPapers = [],
    imageUrl,
    selectedRatio,
    width,
    height,
  } = useAppDataContext();

  const { getBorderPrice } = useCartDataContext();

  const {
    headerTitle,
    totalPrice,
    onClickClose = () => {},
    onClickAddToCart = () => {},
  } = props;

  const paperData = allPapers?.filter((item) => productData.paper === item._id);
  const paperPrice = paperData[0]?.price?.filter(
    (item) => productData.sizes.size_units === item.units
  );

  const imagePrice = () => {
    if (productData?.sizes?.size_type !== 'Standard') {
      const widthInCm =
        productData?.sizes?.size_units === 'cm'
          ? Number(productData?.sizes?.width)
          : Number(productData?.sizes?.width) / 10;
      const heightInCm =
        productData?.sizes?.size_units === 'cm'
          ? Number(productData?.sizes?.height)
          : Number(productData?.sizes?.height) / 10;

      return widthInCm * heightInCm * Number(pricesData.price_for_sqcm);
    } else {
      const sizeData =
        ratiosData?.find((ratio) => ratio[selectedRatio])[selectedRatio] || [];

      const matchingSize = sizeData.find((item) => {
        const itemWidth =
          productData?.sizes?.size_units === 'cm'
            ? item.widthStandard
            : item.widthStandard * 10;
        const itemHeight =
          productData?.sizes?.size_units === 'cm'
            ? item.heightStandard
            : item.heightStandard * 10;

        if (width > height) {
          return (
            Number(itemWidth) === Number(productData?.sizes?.width) &&
            Number(itemHeight) === Number(productData?.sizes?.height)
          );
        } else {
          return (
            Number(itemHeight) === Number(productData?.sizes?.width) &&
            Number(itemWidth) === Number(productData?.sizes?.height)
          );
        }
      });

      return matchingSize?.price || 0;
    }
  };

  const renderHeaderSection = () => {
    return (
      <div className={styles.headerContainerStyle}>
        <h3 className={styles.headerTextStyle}>{headerTitle}</h3>
        <Image
          image={closeIcon}
          altText="close"
          customImageContainerStyle={styles.closeIconStyle}
          onClick={onClickClose}
        />
      </div>
    );
  };
  const renderSummarySection = () => {
    return (
      <div className={styles.detailsContainerStyle}>
        {productData.sizes.size_type ? (
          <React.Fragment>
            <div className={styles.detailsDataWrapperStyle}>
              {renderPrintImageSection()}
              {renderPrintSpecificationSection()}
            </div>
            {renderBtnSection()}
          </React.Fragment>
        ) : (
          <p className={styles.printTypeTextStyle}>
            {strings.noProductSummaryMessage}
          </p>
        )}
      </div>
    );
  };
  const renderPrintImageSection = () => {
    return (
      <div className={styles.printImgContainerStyle}>
        <Image
          image={imageUrl}
          altText="selected image"
          customImageContainerStyle={styles.imageStyle}
        />
        <div className={styles.printImgDetailsWrapperStyle}>
          <p className={styles.printTypeTextStyle}>Giclée Frame & Print</p>
          <p className={styles.imgNameTextStyle}>
            {strings.uploadedImage} {productData.image.name}
          </p>
          <p className={styles.priceTextStyle}>
            {strings.totalPrice}
            <span className={styles.priceValueTextStyle}>{totalPrice}</span>
          </p>
        </div>
      </div>
    );
  };
  const renderPrintSpecificationSection = () => {
    return (
      <div className={styles.specificationsContainerStyle}>
        {productData.sizes && (
          <div className={styles.specItemContainerStyle}>
            <div className={styles.specItemDetailsSectionStyle}>
              <p className={styles.itemHeaderTextStyle}>{strings.imageSize}</p>
              <p className={styles.itemContentTextStyle}>
                {strings.width} {productData.sizes.width}
                {productData.sizes.size_units}; {strings.height}
                {productData.sizes.height} {productData.sizes.size_units};
              </p>
            </div>
            <p className={styles.itemPriceTextStyle}>
              {formatCurrency(imagePrice())}
            </p>
          </div>
        )}
        {productData.paper_border.border_value && (
          <div className={styles.specItemContainerStyle}>
            <div className={styles.specItemDetailsSectionStyle}>
              <p className={styles.itemHeaderTextStyle}>
                {strings.imageBorder}
              </p>
              <p className={styles.itemContentTextStyle}>
                {strings.border} {productData.paper_border.border_type};
                {productData.paper_border.border_place === 'Outside' ? (
                  <span>{strings.outside}</span>
                ) : (
                  <span>{strings.inside}</span>
                )}
                {productData.paper_border.border_type === 'Equal'
                  ? productData.paper_border.border_value
                  : `top:${productData.paper_border.border_value.top}${productData.sizes.size_units} bottom:${productData.paper_border.border_value.bottom}${productData.sizes.size_units} left:${productData.paper_border.border_value.left}${productData.sizes.size_units} right:${productData.paper_border.border_value.right}${productData.sizes.size_units}`}
                {productData.paper_border.border_type === 'Equal' &&
                  productData.sizes.size_units}
              </p>
            </div>
            <p className={styles.itemPriceTextStyle}>
              {formatCurrency(getBorderPrice().toFixed(2))}
            </p>
          </div>
        )}
        {paperData && productData.paper && (
          <div className={styles.specItemContainerStyle}>
            <div className={styles.specItemDetailsSectionStyle}>
              <p className={styles.itemHeaderTextStyle}>{strings.paperType}</p>
              <p className={styles.itemContentTextStyle}>
                {paperData[0].title}
              </p>
            </div>
            <p className={styles.itemPriceTextStyle}>
              {formatCurrency(paperPrice[0].price)}
            </p>
          </div>
        )}
        {productData.frame && (
          <div className={styles.specItemContainerStyle}>
            <div className={styles.specItemDetailsSectionStyle}>
              <p className={styles.itemHeaderTextStyle}>{strings.frameType}</p>
              <p className={styles.itemContentTextStyle}>
                {strings.width} {productData.frame.width}; {strings.height}
                {productData.frame.height};
              </p>
            </div>
            <p className={styles.itemPriceTextStyle}>
              {productData.frame.price}
            </p>
          </div>
        )}
        {productData.mount && (
          <div className={styles.specItemContainerStyle}>
            <div className={styles.specItemDetailsSectionStyle}>
              <p className={styles.itemHeaderTextStyle}>{strings.mountSize}</p>
              <p className={styles.itemContentTextStyle}>
                {strings.width} {productData.mount.width}; {strings.height}
                {productData.mount.height};
              </p>
            </div>
            <p className={styles.itemPriceTextStyle}>
              {productData.mount.price}
            </p>
          </div>
        )}
        {productData.glass && (
          <div className={styles.specItemContainerStyle}>
            <div className={styles.specItemDetailsSectionStyle}>
              <p className={styles.itemHeaderTextStyle}>{strings.glassType}</p>
              <p className={styles.itemContentTextStyle}>
                {strings.width} {productData.glass.width}; {strings.height}
                {productData.glass.height};
              </p>
            </div>
            <p className={styles.itemPriceTextStyle}>
              {productData.glass.price}
            </p>
          </div>
        )}
      </div>
    );
  };
  const renderBtnSection = () => {
    return (
      <div className={styles.btnSectionStyle}>
        <Button
          title={strings.addToCart}
          onClick={onClickAddToCart}
          customBtnContainerStyle={styles.btnStyle}
        />
      </div>
    );
  };
  return (
    <div className={styles.mainContainerStyle}>
      {renderHeaderSection()}

      {renderSummarySection()}
    </div>
  );
};

export default ProductSummary;
ProductSummary.propTypes = {
  headerTitle: propTypes.string,
  data: propTypes.object,
  onClickClose: propTypes.func,
  onClickAddToCart: propTypes.func,
};
