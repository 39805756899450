export const convertPixelsToUnits = (pixels, unit = 'mm', dpi = 96) => {
  const conversionFactor = unit === 'cm' ? 2.54 : 25.4;
  return ((pixels / dpi) * conversionFactor).toFixed(2);
};

export const getImageDimensions = (file, dpi = 96) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new window.Image();
      img.onload = () => {
        const widthMm = convertPixelsToUnits(img.width, 'mm', dpi);
        const heightMm = convertPixelsToUnits(img.height, 'mm', dpi);
        const widthCm = convertPixelsToUnits(img.width, 'cm', dpi);
        const heightCm = convertPixelsToUnits(img.height, 'cm', dpi);
        resolve({
          mm: { width: widthMm, height: heightMm },
          cm: { width: widthCm, height: heightCm },
        });
      };
      img.onerror = (error) => reject(error);
      img.src = event.target.result;
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

// format currency

export const formatCurrency = (value, currencySymbol = 'SEK') => {
  const fixedValue = parseFloat(value).toFixed(2);

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let formattedValue = formatter.format(fixedValue);
  return `${currencySymbol} ${formattedValue}`;
};
