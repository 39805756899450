import React from 'react';
import { Image } from 'components/common/image';
import { deleteIcon, minusIcon, plusIcon } from 'resources/images';
import propTypes from 'prop-types';
import { useStrings } from 'providers/stringsprovider';
import { priceFormatter } from 'validations';
import classNames from 'classnames';
import styles from './styles.module.css';

const ShoppingCartCard = (props) => {
  const {
    image,
    altText,
    cardTitle,
    paper,
    border,
    totalPrice,
    quantity,
    onClickMinus = () => {},
    onClickPlus = () => {},
    onClickDelete = () => {},
    showCartActions,
    orderedQuantity,

    customCardContainerStyle,
    customCardMediaStyle,
    customImageStyle,
  } = props;

  const { strings } = useStrings();

  const renderTopSection = () => {
    return (
      <section className={styles.topCardSurfaceStyle}>
        <div className={styles.topLeftCardContentStyle}>
          <Image
            image={image}
            altText={altText}
            customImageContainerStyle={classNames(
              styles.topLeftCardMediaStyle,
              customCardMediaStyle
            )}
            customImageStyle={classNames(styles.imageStyle, customImageStyle)}
          />
          <div className={styles.cardContentStyle}>
            <h6 className={styles.cardTitleStyle}>{cardTitle}</h6>
            <div className={styles.specificationStyle}>
              <p className={styles.specificationTitleStyle}>
                {strings.paper}: &nbsp;
                <span className={styles.specificationDetailStyle}>{paper}</span>
              </p>
              <p className={styles.specificationTitleStyle}>
                {strings.border} &nbsp;
                <span className={styles.specificationDetailStyle}>
                  {border}
                </span>
              </p>
              <p className={styles.specificationTitleStyle}>
                {'Quantity'}: &nbsp;
                <span className={styles.specificationDetailStyle}>
                  {orderedQuantity}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.topRightCardContentStyle}>
          <p className={styles.priceTextStyle}>{priceFormatter(totalPrice)}</p>
        </div>
      </section>
    );
  };

  const renderBottomSection = () => {
    return (
      <section className={styles.bottomCardSurfaceStyle}>
        <div className={styles.bottomLeftCardActionStyle}>
          <p className={styles.quantityTextStyle}>{strings.quantity}</p>
          <div className={styles.bottomCardActionAreaStyle}>
            <Image
              image={minusIcon}
              altText={strings.subtractAltText}
              customImageContainerStyle={styles.minusIconStyle}
              onClick={onClickMinus}
            />
            <p className={styles.countTextStyles}>{quantity || 1}</p>
            <Image
              image={plusIcon}
              altText={strings.plusIconAltText}
              customImageContainerStyle={styles.plusIconStyle}
              onClick={onClickPlus}
            />
          </div>
        </div>
        <Image
          image={deleteIcon}
          altText={strings.deleteIconAltText}
          customImageContainerStyle={styles.deleteIconStyle}
          onClick={onClickDelete}
        />
      </section>
    );
  };

  return (
    <main
      className={classNames(
        styles.cardContainerStyle,
        customCardContainerStyle
      )}
    >
      {renderTopSection()}
      {showCartActions && renderBottomSection()}
    </main>
  );
};

export default ShoppingCartCard;
ShoppingCartCard.propTypes = {
  showCartActions: propTypes.bool,
  orderedQuantity: propTypes.any,
  image: propTypes.string,
  altText: propTypes.string,
  title: propTypes.string,
  cardTitle: propTypes.string,
  paper: propTypes.string,
  border: propTypes.string,
  totalPrice: propTypes.string,
  quantity: propTypes.any,
  onClickMinus: propTypes.func,
  onClickPlus: propTypes.func,
  onClickDelete: propTypes.func,

  customCardContainerStyle: propTypes.string,
  customCardMediaStyle: propTypes.string,
  customImageStyle: propTypes.string,
};
