import axios from 'utils/axios';
import * as endPoints from 'networking/endpoints';

export const userRegistrationApi = async (data) =>
  Promise.resolve(axios.post(endPoints.register, data));

export const userLoginApi = async (data) =>
  Promise.resolve(axios.post(endPoints.login, data));

export const forgetPwOtpApi = async (data) =>
  Promise.resolve(axios.post(endPoints.forgetPwOtp, data));

export const forgetPwOtpVerifyApi = async (data) =>
  Promise.resolve(axios.post(endPoints.forgetPwVerifyOtp, data));

export const updatePasswordApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updatePassword, data));

export const updateUserDetailsApi = async (data) =>
  Promise.resolve(axios.post(endPoints.updateUserDetails, data));
