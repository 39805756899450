// admin ENDPOINTS
export const adminLogin = '/admin/login';
export const createPaper = '/paper/create';
export const updatePrice = '/price/update';
export const allOrders = '/orders/get/allorders/{id}';

// User ENDPOINTS
export const register = '/user/register';
export const login = '/user/login';
export const forgetPwOtp = '/user/forgetpassword/otp';
export const forgetPwVerifyOtp = '/user/forgetpassword/otp-verify';
export const updatePassword = '/user/forgetpassword/update';
export const updateUserDetails = '/user/update';

// cart ENDPOINTS
export const addToCart = '/cart/add';
export const getAllCartItems = '/cart/get/all/{id}';
export const updateCart = '/cart/update';
export const deleteCart = '/cart/delete/{id}';

//orders ENDPOINTS
export const purchase = '/orders/purchase';
export const getAllOrders = '/orders/get/all/{id}';

//upload image ENDPOINTS
export const uploadImage = '/upload/image';

//price ENDPOINTS
export const getPrices = '/price/get/prices';

//paper ENDPOINTS
export const getPapers = '/paper/get/all';
export const deletePaper = '/paper/delete/{id}';
export const updatePaper = '/paper/update';

//frame ENDPOINTS
export const getFrames = '/frame/get/all';

// contry ENDPOINTS
export const countriesData = 'https://restcountries.com/v2/all';
