import React from 'react';
import Header from 'components/common/header';
import { Navigate, useLocation } from 'react-router-dom';
import Menu from 'components/menu';
import styles from './styles.module.css';
import Home from 'pages/home';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { routeNames } from 'constants';

const Layout = () => {
  const location = useLocation();
  const { productData } = useAppDataContext();
  if (
    (location.pathname === routeNames.uploadOrChangeImage ||
      location.pathname === routeNames.imageSizeAndCrop ||
      location.pathname === routeNames.selectBorder ||
      location.pathname === routeNames.choosePaper) &&
    !productData.image
  ) {
    return <Navigate to={routeNames.home} replace />;
  }
  return (
    <main className={styles.layOutMainStyle}>
      <div className={styles.layOutStyle}>
        <Header />
        <div className={styles.contentWrapperStyle}>
          <Home />
          <Menu />
        </div>
      </div>
    </main>
  );
};

export default Layout;
