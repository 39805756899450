import React from 'react';
import { Navigate } from 'react-router-dom';
import { routeNames } from 'constants';

const AuthGuard = (props) => {
  const { children } = props;
  const userType = localStorage.getItem('userType');
  if (userType !== 'User') {
    return <Navigate to={routeNames.login} replace />;
  }

  return <>{children}</>;
};

export default AuthGuard;
