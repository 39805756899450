import { BrowserRouter } from 'react-router-dom';
import { NetWorkProvider } from 'providers/networkprovider';
import { AppDataProvider } from 'providers/appdataprovider';
import { ThemeProvider } from 'providers/themeprovider';
import { AppRoutes } from 'routes';
import StringsProvider from 'providers/stringsprovider';
import { AuthProvider } from 'providers/authprovider';
import { ToastProvider } from 'providers/toasterprovider';
import { CartDataProvider } from 'providers/cartdataprovider';

function App() {
  return (
    <BrowserRouter>
      <ToastProvider>
        <NetWorkProvider>
          <StringsProvider>
            <ThemeProvider>
              <AppDataProvider>
                <AuthProvider>
                  <CartDataProvider>
                    <AppRoutes />
                  </CartDataProvider>
                </AuthProvider>
              </AppDataProvider>
            </ThemeProvider>
          </StringsProvider>
        </NetWorkProvider>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
