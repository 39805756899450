import React, { useRef } from 'react';
import useDragDropHook from 'hooks/usedragdrophook';
import classNames from 'classnames';
import { useStrings } from 'providers/stringsprovider';
import styles from './styles.module.css';

const FileUploadInput = (props) => {
  // props:
  const {
    label,
    setSelectedFile,
    accept,
    image,
    customLabelStyle,
    customUploadIconStyle,
  } = props;

  const { strings } = useStrings();

  // refs:
  const fileInputRef = useRef(null);

  // hook for the drag and drop functionality
  const { dragOverProps } = useDragDropHook((file) => setSelectedFile(file));

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUploadFile = (event) => {
    event.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div {...dragOverProps}>
      <input
        type="file"
        onChange={handleFileChange}
        className={styles.inputStyle}
        id="fileInput"
        accept={accept}
        ref={fileInputRef}
      />
      {label ? (
        <label
          htmlFor="fileInput"
          className={classNames(styles.labelTextStyle, customLabelStyle)}
          onClick={handleUploadFile}
        >
          {label}
        </label>
      ) : (
        <div
          className={classNames(
            styles.uploadIconBlockStyle,
            customUploadIconStyle
          )}
          onClick={handleUploadFile}
        >
          <div className={styles.uploadIconStyle}>
            <img
              src={image}
              alt={strings.uploadedImage}
              className={styles.imageStyle}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploadInput;
