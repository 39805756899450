import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.css';
import { Image } from '../image';

const Chip = (props) => {
  const {
    reference,
    label,
    onClick,
    containerStyle,
    labelStyle,
    leftIcon,
    leftIconStyle,
    rightIcon,
    rightIconStyle,
    children,
  } = props;

  return (
    <div
      className={classNames(
        styles.containerStyle,
        containerStyle,
        onClick && styles.pointerStyle
      )}
      onClick={onClick}
      ref={reference}
    >
      {leftIcon && (
        <Image
          customImageContainerStyle={classNames(
            styles.leftIconStyle,
            leftIconStyle
          )}
          image={leftIcon}
          altText="icon"
        />
      )}
      <span className={classNames(styles.labelStyle, labelStyle)}>{label}</span>
      {rightIcon && (
        <Image
          customImageContainerStyle={classNames(
            styles.rightIconStyle,
            rightIconStyle
          )}
          image={rightIcon}
          altText="icon"
        />
      )}
      {children && children}
    </div>
  );
};

Chip.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  containerStyle: PropTypes.string,
  labelStyle: PropTypes.string,
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  leftIcon: PropTypes.string,
  leftIconStyle: PropTypes.string,
  rightIcon: PropTypes.string,
  rightIconStyle: PropTypes.string,
};

export default Chip;
