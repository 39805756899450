import React, { useEffect, useState } from 'react';
import { Image } from 'components/common/image';
import { closeBgIcon, uploadIcon, uploadSampleImg } from 'resources/images';
import useDragDropHook from 'hooks/usedragdrophook';
import FileUploadInput from 'components/common/fileuploadInput';
import { Button } from 'components/common/button';
import { useStrings } from 'providers/stringsprovider';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { useNavigate, useLocation } from 'react-router-dom';
import { routeNames } from 'constants';
import { useBeforeUnload } from 'hooks/usebeforeunload';
import useWindowDimensions from 'hooks/UseWindowDimensionHook';
import styles from './styles.module.css';

const UploadOrChangeImage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // contexts:
  const { strings } = useStrings();
  const {
    productData,
    setProductData,
    imageUrl,
    setImageUrl,
    onBeforeLoad,
    setOnBeforeUnLoad,
    resetProductData,
  } = useAppDataContext();

  // hooks
  useBeforeUnload(onBeforeLoad);

  const { widthScreen } = useWindowDimensions();

  // states:
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage('');
    }, 2000);
  }, [errorMessage]);

  // handle file upload:
  const handleFileChange = (file) => {
    resetProductData();
    if (!file) {
      return;
    }

    const allowedFormats = ['.jpg', '.jpeg', '.png', '.tif', '.tiff'];
    const fileType = file.name
      .slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2)
      .toLowerCase();

    if (!allowedFormats.includes(`.${fileType}`)) {
      setErrorMessage(strings.fileSupportMsg);
      return;
    } else {
      setProductData((prev) => ({
        ...prev,
        image: file,
      }));
      setImageUrl(URL.createObjectURL(file));
      setIsLoading(true);
      setOnBeforeUnLoad(true);
      if (location.pathname === routeNames.home) {
        navigate(routeNames.uploadOrChangeImage);
      }
    }
  };

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 1200);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  const { dragOverProps, isDragging } = useDragDropHook(handleFileChange);

  const renderTopSection = () => {
    return (
      <section
        className={
          isDragging
            ? styles.topFileUploadRegionStyle
            : widthScreen <= 549 &&
              location.pathname === routeNames.uploadOrChangeImage
            ? styles.hideTopBlockStyle
            : styles.topBlockStyle
        }
        {...dragOverProps}
      >
        <div className={styles.uploadImgStyle}>
          <FileUploadInput
            image={uploadIcon}
            selectedFile={productData.image}
            setSelectedFile={handleFileChange}
            customUploadIconStyle={styles.uploadIconStyle}
          />
        </div>
        <p className={styles.uploadImgInfoTitleStyle}>
          {strings.fileUploadInfoTitle} &nbsp;
          <span className={styles.uploadImgDescStyle}>
            {strings.fileUploadDesc}
          </span>
        </p>
        <div className={styles.dividerBlockStyle}>
          <div className={styles.leftDividerStyle}></div>
          <p className={styles.orTextStyle}>{strings.or}</p>
          <div className={styles.rightDividerStyle}></div>
        </div>
        <FileUploadInput
          label={strings.browseFile}
          selectedFile={productData.image}
          setSelectedFile={handleFileChange}
          customLabelStyle={styles.fileUploadBtnStyle}
        />
      </section>
    );
  };

  const renderBottomSection = () => {
    return (
      <section className={styles.bottomBlockStyle}>
        <div className={styles.bottomSubBlockStyle}>
          {productData.image && (
            <>
              {isLoading && (
                <div className={styles.loaderOverlay}>
                  <div className={styles.loader}></div>
                </div>
              )}

              {widthScreen <= 549 &&
              location.pathname === routeNames.uploadOrChangeImage ? (
                <div className={styles.imgAndFileUploadBtnStyle}>
                  <div customImageStyle={styles.uploadedImgStyle}>
                    <Image
                      image={imageUrl ? imageUrl : uploadSampleImg}
                      altText={strings.uploadedImage}
                      onClick={() => {}}
                      customImageContainerStyle={
                        styles.uploadedImageContainerStyle
                      }
                      customImageStyle={styles.uploadedImgStyle}
                      onLoad={() => setIsLoading(false)}
                    />
                  </div>
                  <FileUploadInput
                    label={strings.browseFile}
                    selectedFile={productData.image}
                    setSelectedFile={handleFileChange}
                    customLabelStyle={styles.fileUploadLabelStyle}
                  />
                </div>
              ) : (
                <Image
                  image={imageUrl ? imageUrl : uploadSampleImg}
                  altText={strings.uploadedImage}
                  onClick={() => {}}
                  customImageContainerStyle={styles.uploadedImageContainerStyle}
                  customImageStyle={styles.uploadedImgStyle}
                  onLoad={() => setIsLoading(false)}
                />
              )}
            </>
          )}
          <div className={styles.closeIconBlcokStyle}>
            {productData.image && (
              <Image
                image={closeBgIcon}
                altText={strings.closeBgIcon}
                customImageContainerStyle={styles.closeBgCotainerStyle}
                onClick={() => {
                  setProductData({ ...productData, image: null });
                  setImageUrl(null);
                }}
              />
            )}
          </div>
        </div>
        {errorMessage && (
          <p className={styles.errorMessageStyle}>{errorMessage}</p>
        )}
        {location.pathname === routeNames.uploadOrChangeImage && (
          <div className={styles.bottomBtnStyle}>
            <Button
              title={strings.applyChanges}
              customBtnContainerStyle={styles.applyChangesBtnStyle}
              disabled={isLoading ? true : false}
              onClick={() => navigate(routeNames.imageSizeAndCrop)}
              customBtnTitleStyle={styles.applyChangesBtnTItleStyle}
            />
          </div>
        )}
      </section>
    );
  };

  return (
    <main className={styles.uploadImgContainerStyle}>
      {renderTopSection()}
      {renderBottomSection()}
    </main>
  );
};

export default UploadOrChangeImage;
