import React, { useState, useRef, useEffect } from 'react';
import { Image } from 'components/common/image';
import { bannerImage } from 'resources/images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { routeNames, editImageIconsData } from 'constants';
import classNames from 'classnames';
import UploadOrChangeImage from 'pages/upload-or-changeImage';
import { useStrings } from 'providers/stringsprovider';
import useWindowDimensions from 'hooks/UseWindowDimensionHook';
import styles from './styles.module.css';

const ImageEditor = () => {
  const location = useLocation();
  const {
    imageUrl,
    aspectRatio,
    productData,
    setImageUrl,
    setProductData,
    isCropperVisible,
    setIsCropperVisible,
    cropperRef,
    handleCrop,
    widthImg,
    heightImg,
    divRef,
    scaleValue,
    setScaleValue,
    lockWithAndHeight,
    sizePercentage,
    width,
    height,
  } = useAppDataContext();

  const { strings } = useStrings();

  const { widthScreen } = useWindowDimensions();

  const {
    paper_border: { border_type, border_value, border_units, border_place },
  } = productData;
  const [editImgAction, setEditImageAction] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const originalRefImg = useRef();
  useEffect(() => {
    if (isCropperVisible) {
      setEditImageAction(true);
    } else {
      setEditImageAction(false);
    }
  }, [isCropperVisible]);

  // handle Border

  const isBorderConditionMet = (type, place) => {
    const validPaths = [
      routeNames.home,
      routeNames.choosePaper,
      routeNames.selectBorder,
    ];
    const invalidPaths = [
      routeNames.uploadOrChangeImage,
      routeNames.imageSizeAndCrop,
    ];

    return (
      validPaths.includes(location.pathname) &&
      !invalidPaths.includes(location.pathname) &&
      border_type === type &&
      border_place === place
    );
  };

  const isEqualInSide = isBorderConditionMet('Equal', 'Inside');
  const isEqualOutSide = isBorderConditionMet('Equal', 'Outside');
  const isCustomInside = isBorderConditionMet('Custom', 'Inside');
  const isCustomOutside = isBorderConditionMet('Custom', 'Outside');

  // console.log('isCustomOutside', isCustomOutside);

  const borderTop = `${border_value.top}`;
  const borderBottom = `${border_value.bottom}`;
  const borderRight = `${border_value.right}`;
  const borderLeft = `${border_value.left}`;

  // const [widthImg, setWidthImg] = useState(0);
  // const [heightImg, setHeightImg] = useState(0);
  const [xvalue, setXValue] = useState(0);
  const [yvalue, setYValue] = useState(0);

  // useEffect(() => {
  //   if (location.pathname === routeNames.selectBorder) {
  //     const { width, height } = divRef.current.getBoundingClientRect();
  //     setWidthImg(width);
  //     setHeightImg(height);
  //   }
  // }, [location.pathname === routeNames.selectBorder]);

  useEffect(() => {
    getScaleValue();
  }, [border_value]);
  useEffect(() => {
    if (borderBottom > 0) {
      setYValue(
        heightImg /
          (heightImg +
            (Number(borderTop) * heightImg) / Number(productData.sizes.height) +
            (Number(borderBottom) * heightImg) /
              Number(productData.sizes.height))
      );
    } else {
      setYValue(
        heightImg /
          (heightImg +
            (Number(borderTop) * heightImg) / Number(productData.sizes.height))
      );
    }
  }, [borderTop]);
  useEffect(() => {
    if (borderTop > 0) {
      setYValue(
        heightImg /
          (heightImg +
            (Number(borderTop) * heightImg) / Number(productData.sizes.height) +
            (Number(borderBottom) * heightImg) /
              Number(productData.sizes.height))
      );
    } else {
      setYValue(
        heightImg /
          (heightImg +
            (Number(borderBottom) * heightImg) /
              Number(productData.sizes.height))
      );
    }
  }, [borderBottom]);
  useEffect(() => {
    if (borderRight > 0) {
      setXValue(
        heightImg /
          (heightImg +
            (Number(borderRight) * heightImg) /
              Number(productData.sizes.height) +
            (Number(borderLeft) * heightImg) / Number(productData.sizes.height))
      );
    } else {
      setXValue(
        heightImg /
          (heightImg +
            (Number(borderLeft) * heightImg) / Number(productData.sizes.height))
      );
    }
  }, [borderLeft]);
  useEffect(() => {
    if (borderLeft > 0) {
      setXValue(
        heightImg /
          (heightImg +
            (Number(borderRight) * heightImg) /
              Number(productData.sizes.height) +
            (Number(borderLeft) * heightImg) / Number(productData.sizes.height))
      );
    } else {
      setXValue(
        heightImg /
          (heightImg +
            (Number(borderRight) * heightImg) /
              Number(productData.sizes.height))
      );
    }
  }, [borderRight]);
  // useEffect(() => {
  //   setValue(
  //     Number(borderTop) +
  //       Number(borderBottom) +
  //       Number(borderLeft) +
  //       Number(borderRight)
  //   );
  // }, [borderBottom]);
  const getScaleValue = () => {
    if (isEqualInSide || isEqualOutSide) {
      if (widthImg > heightImg) {
        setScaleValue(
          Number(
            heightImg /
              (heightImg +
                2 * ((border_value * widthImg) / productData.sizes.width))
          )
        );
      } else {
        setScaleValue(
          Number(
            widthImg /
              (widthImg +
                2 * ((border_value * widthImg) / productData.sizes.width))
          )
        );
      }
    } else if (isCustomOutside || isCustomInside) {
      // if (widthImg > heightImg) {
      //   setScaleValue(
      //     Number(
      //       heightImg /
      //         (heightImg + (value * heightImg) / productData.sizes.width)
      //     )
      //   );
      // } else {
      //   setScaleValue(
      //     Number(
      //       widthImg / (widthImg + (value * widthImg) / productData.sizes.width)
      //     )
      //   );
      // }
    }
  };
  // handle Edit Image Actions
  const handleEditImage = (name) => {
    if (name === 'cropIcon') {
      if (editImgAction === name) {
        setEditImageAction('');
        setIsCropperVisible(false);
        return;
      } else {
        setEditImageAction(name);
        setIsCropperVisible(true);
        return;
      }
    }
    setEditImageAction(name);
    setIsCropperVisible(true);
    const cropper = cropperRef.current;

    switch (name) {
      case 'cropIcon':
        setIsCropperVisible(true);
        break;

      case 'moveIcon':
        cropper?.moveImage(50, 100);
        break;

      case 'zoomInIcon':
        cropper?.zoomImage(1.1);

        break;

      case 'zoomOutIcon':
        cropper?.zoomImage(0.9);

        break;

      case 'leftRotateIcon':
        cropper?.rotateImage(-90);

        break;

      case 'rightRotateIcon':
        cropper?.rotateImage(90);

        break;
      case 'checkIcon':
        handleCrop();
        break;
      case 'resetIcon':
        setEditImageAction(false);
        if (
          productData.sizes.size_type === 'Custom' ||
          productData.sizes.size_type === 'Perfect'
        ) {
          setIsCropperVisible(false);
        }
        setImageUrl(URL.createObjectURL(productData.image));
        setProductData((prev) => ({
          ...prev,
          edited_image: '',
          sizes: {
            ...prev.sizes,
            aspect_ratio: '',
          },
        }));
        break;
      default:
        break;
    }
  };
  const calculateDimensions = () => {
    let calculatedWidth = '';
    let calculatedHeight = '';

    if (
      (productData.image &&
        location.pathname === routeNames.imageSizeAndCrop) ||
      location.pathname === routeNames.selectBorder
    ) {
      if (
        location.pathname === routeNames.selectBorder &&
        border_type === 'Custom' &&
        border_place === 'Outside'
      ) {
        calculatedWidth = `${
          Number(productData.sizes.width) +
          Number(borderRight || 0) +
          Number(borderLeft || 0)
        } ${productData.sizes.size_units}`;

        calculatedHeight = `${
          Number(productData.sizes.height) +
          Number(borderTop || 0) +
          Number(borderBottom || 0)
        } ${productData.sizes.size_units}`;
      } else if (
        location.pathname === routeNames.selectBorder &&
        border_place === 'Outside'
      ) {
        calculatedWidth = `${
          Number(productData.sizes.width) + 2 * (Number(border_value) || 0)
        } ${productData.sizes.size_units}`;
        calculatedHeight = `${
          Number(productData.sizes.height) + 2 * (Number(border_value) || 0)
        } ${productData.sizes.size_units}`;
      } else {
        calculatedWidth = `${productData.sizes.width} ${productData.sizes.size_units}`;
        calculatedHeight = `${productData.sizes.height} ${productData.sizes.size_units}`;
      }
    }

    return { calculatedWidth, calculatedHeight };
  };

  const renderImageEditSection = () => {
    return (
      <section className={styles.imageEditBlockStyle}>
        {editImageIconsData
          .filter((item) => editImgAction || item?.name !== 'checkIcon')
          .map((item, index) => {
            return (
              <div
                className={
                  editImgAction === item.name
                    ? styles.activeEditImgActionStyle
                    : styles.editImgActionIconBlockStyle
                }
                key={index}
                onClick={() => handleEditImage(item.name)}
              >
                <Image
                  image={item.icon}
                  altText={item.name}
                  customImageContainerStyle={styles.editImgActionIconStyle}
                />
              </div>
            );
          })}
      </section>
    );
  };
  return (
    <main
      className={
        location.pathname === routeNames.imageSizeAndCrop
          ? styles.homeContainerStyle1
          : location.pathname === routeNames.choosePaper && widthScreen <= 549
          ? styles.homeContainerHiddenStyle
          : styles.homeContainerStyles
      }
    >
      <div className={styles.toolsSectionStyle}>
        {location.pathname === routeNames.imageSizeAndCrop &&
          renderImageEditSection()}
      </div>
      <div
        className={styles.imageMainSectionStyle}
        style={{
          margin: imageUrl && 'auto',
        }}
      >
        <div className={styles.imageAndCropperSectionWrapperStyle}>
          {location.pathname !== routeNames.home &&
            location.pathname !== routeNames.uploadOrChangeImage && (
              <div className={styles.imageHeightSectionStyle}>
                {productData.image &&
                  (location.pathname === routeNames.imageSizeAndCrop ||
                    location.pathname === routeNames.selectBorder) && (
                    <p className={styles.imageUnitHeightTextStyle}>
                      {`${calculateDimensions().calculatedHeight} 
                `}
                    </p>
                  )}
              </div>
            )}

          {isCropperVisible &&
          location.pathname === routeNames.imageSizeAndCrop ? (
            <div className={styles.cropperStyle}>
              <Cropper
                ref={cropperRef}
                src={URL.createObjectURL(productData.image)}
                className={styles.imageStyle}
                stencilProps={{
                  aspectRatio:
                    productData.sizes.size_type === 'Custom' &&
                    !lockWithAndHeight
                      ? productData.sizes.width / productData.sizes.height
                      : (aspectRatio * 10) / 10,
                  grid: true,
                  gridColor: 'rgba(255, 0, 0, 0.8)',
                  gridOpacity: 0.8,
                }}
              />
            </div>
          ) : (
            <div className={styles.imageMainContainerStyle} style={{}}>
              {imageUrl ? (
                <Image
                  isLoading={isLoading}
                  onLoad={() => setIsLoading(false)}
                  originalImgRef={originalRefImg}
                  imageRef={divRef}
                  image={imageUrl && imageUrl}
                  altText={strings.bannerImage}
                  style={{
                    minWidth: (isCustomInside || isCustomOutside) && '50px',
                    minHeight: (isCustomInside || isCustomOutside) && '50px',
                    objectFit:
                      productData.sizes.size_type === 'Custom' &&
                      !lockWithAndHeight &&
                      'cover',
                    width:
                      productData.sizes.size_type === 'Custom' &&
                      !lockWithAndHeight &&
                      '100%',
                  }}
                  imageContainerInlineStyle={{
                    aspectRatio:
                      productData.sizes.size_type === 'Custom' &&
                      !lockWithAndHeight &&
                      productData.sizes.width / productData.sizes.height,
                    transform:
                      (location.pathname === routeNames.home ||
                        location.pathname === routeNames.selectBorder ||
                        location.pathname === routeNames.choosePaper) &&
                      (isEqualOutSide || isEqualInSide) &&
                      `scale(${scaleValue})`,
                    transition:
                      (isEqualOutSide || isEqualInSide) &&
                      'transform 0.2s ease-in-out',
                    position: (isCustomInside || isCustomOutside) && 'relative',
                    objectFit:
                      location.pathname === routeNames.imageSizeAndCrop &&
                      productData.sizes.size_type === 'Standard' &&
                      'cover',
                    boxShadow:
                      (isEqualOutSide || isEqualInSide) &&
                      `0 0 0 ${
                        (border_value * widthImg) / productData.sizes.width
                      }px rgba(255, 255, 255, 1), 1px 2px 51.1px 0px rgba(0, 0, 0, 0.5)`,

                    // borderInline:
                    //   isEqualInSide &&
                    //   `${
                    //     (border_value * widthImg) / productData.sizes.width
                    //   }px solid rgba(255, 255, 240, 1)`,
                    // borderBlockStart:
                    //   isEqualInSide &&
                    //   `${
                    //     (border_value * widthImg) / productData.sizes.width
                    //   }px solid rgba(255, 255, 240, 1)`,
                    // borderBlockEnd:
                    //   isEqualInSide &&
                    //   `${
                    //     (border_value * widthImg) / productData.sizes.width
                    //   }px solid rgba(255, 255, 240, 1)`,
                    // paddingTop:
                    //   (isCustomInside || isCustomOutside) &&
                    //   `${widthImg - widthImg / borderTop}px`,
                    // paddingRight:
                    //   (isCustomInside || isCustomOutside) &&
                    //   ` ${widthImg - widthImg / borderRight}px`,
                    // paddingBottom:
                    //   (isCustomInside || isCustomOutside) &&
                    //   ` ${widthImg - widthImg / borderBottom}px`,
                    // paddingLeft:
                    //   (isCustomInside || isCustomOutside) &&
                    //   ` ${widthImg - widthImg / borderLeft}px`,
                    // minWidth: (isCustomInside || isCustomOutside) && widthImg,
                    // minHeight: (isCustomInside || isCustomOutside) && heightImg,
                    maxWidth: (isCustomInside || isCustomOutside) && widthImg,
                    maxHeight: (isCustomInside || isCustomOutside) && heightImg,
                    background:
                      (isCustomInside || isCustomOutside) &&
                      'rgba(255, 255, 255, 1)',
                    borderTop: isCustomInside
                      ? borderTop &&
                        `${
                          ((Number(borderTop) * heightImg) /
                            Number(productData.sizes.height)) *
                          yvalue
                        }px solid rgba(255, 255, 255, 1)`
                      : isCustomOutside &&
                        borderTop &&
                        `${
                          ((Number(borderTop) * heightImg) /
                            Number(productData.sizes.height)) *
                          yvalue
                        }px solid rgba(255, 255, 255, 1)`,

                    borderBottom: isCustomInside
                      ? borderBottom &&
                        `${
                          ((Number(borderBottom) * heightImg) /
                            Number(productData.sizes.height)) *
                          yvalue
                        }px solid rgba(255, 255, 255, 1)`
                      : isCustomOutside &&
                        borderBottom &&
                        `${
                          ((Number(borderBottom) * heightImg) /
                            Number(productData.sizes.height)) *
                          yvalue
                        }px solid rgba(255, 255, 255, 1)`,

                    borderRight: isCustomInside
                      ? borderRight &&
                        `${
                          ((Number(borderRight) * widthImg) /
                            Number(productData.sizes.width)) *
                          xvalue
                        }px solid rgba(255, 255, 255, 1)`
                      : isCustomOutside &&
                        borderRight &&
                        `${
                          ((Number(borderRight) * widthImg) /
                            Number(productData.sizes.width)) *
                          xvalue
                        }px solid rgba(255, 255, 255, 1)`,

                    borderLeft: isCustomInside
                      ? borderLeft &&
                        `${
                          ((Number(borderLeft) * widthImg) /
                            Number(productData.sizes.width)) *
                          xvalue
                        }px solid rgba(255, 255, 255, 1)`
                      : isCustomOutside &&
                        borderLeft &&
                        `${
                          ((Number(borderLeft) * widthImg) /
                            Number(productData.sizes.width)) *
                          xvalue
                        }px solid rgba(255, 255, 255, 1)`,
                  }}
                  customImageContainerStyle={classNames(
                    imageUrl
                      ? location.pathname === routeNames.selectBorder
                        ? styles.imageWrapperStyle2
                        : styles.imageWrapperStyle
                      : styles.imageBannerWrapperStyle,
                    location.pathname === routeNames.selectBorder &&
                      styles.windowFitImgStyle
                  )}
                  customImageStyle={`${
                    productData.sizes.size_type === 'Custom' || 'Standard'
                      ? styles.imageStyle
                      : styles.imageStyle2
                  } ${styles.tranformedImgStyle}`}
                >
                  {/* {(isCustomOutside || isCustomInside) && (
                    <>
                      <div
                        className={styles.outsideDiv1}
                        style={{
                          top: `-${
                            (borderTop * widthImg) / productData.sizes.width - 1
                          }px`,
                          borderTop: `${
                            (borderTop * widthImg) / productData.sizes.width
                          }px solid rgba(255, 255, 250, 1)`,
                        }}
                      ></div>
                      {borderTop && borderRight && (
                        <div
                          style={{
                            position: 'absolute',
                            top: `-${
                              (borderTop * widthImg) / productData.sizes.width -
                              1
                            }px`,
                            right: `-${
                              (borderRight * widthImg) /
                                productData.sizes.width -
                              1
                            }px`,
                            width: `${
                              (borderRight * widthImg) / productData.sizes.width
                            }px`,
                            height: `${
                              (borderTop * widthImg) / productData.sizes.width
                            }px`,
                            backgroundColor: 'rgba(255, 255, 240, 1)',
                          }}
                        ></div>
                      )}
                      <div
                        className={styles.outsideDiv2}
                        style={{
                          bottom: `-${
                            (borderBottom * widthImg) / productData.sizes.width
                          }px`,
                          borderBottom: `${
                            (borderBottom * widthImg) / productData.sizes.width
                          }px solid rgba(255, 255, 240, 1)`,
                        }}
                      >
                        {borderRight && borderBottom && (
                          <div
                            style={{
                              position: 'absolute',
                              right: `-${
                                (borderRight * widthImg) /
                                  productData.sizes.width -
                                1
                              }px`,
                              bottom: `-${
                                (borderBottom * widthImg) /
                                  productData.sizes.width -
                                1
                              }px`,
                              width: `${
                                (borderRight * widthImg) /
                                productData.sizes.width
                              }px`,
                              height: `${
                                (borderBottom * widthImg) /
                                productData.sizes.width
                              }px`,
                              backgroundColor: 'rgba(255, 255, 240, 1)',
                            }}
                          ></div>
                        )}
                      </div>
                      <div
                        className={styles.outsideDiv3}
                        style={{
                          right: `-${
                            (borderRight * widthImg) / productData.sizes.width
                          }px`,
                          borderRight: `${
                            (borderRight * widthImg) / productData.sizes.width
                          }px solid rgba(255, 255, 240, 1)`,
                        }}
                      ></div>
                      {borderBottom && borderLeft && (
                        <div
                          style={{
                            position: 'absolute',
                            left: `-${
                              (borderLeft * widthImg) / productData.sizes.width
                            }px`,
                            bottom: `-${
                              (borderBottom * widthImg) /
                                productData.sizes.width -
                              1
                            }px`,
                            width: `${
                              (borderLeft * widthImg) / productData.sizes.width
                            }px`,
                            height: `${
                              (borderBottom * widthImg) /
                              productData.sizes.width
                            }px`,
                            backgroundColor: 'rgba(255, 255, 240, 1)',
                          }}
                        ></div>
                      )}
                      <div
                        className={styles.outsideDiv4}
                        style={{
                          left: `-${
                            (borderLeft * widthImg) / productData.sizes.width
                          }px`,
                          borderLeft: `${
                            (borderLeft * widthImg) / productData.sizes.width
                          }px solid rgba(255, 255, 240, 1)`,
                        }}
                      ></div>
                      {borderTop && borderLeft && (
                        <div
                          style={{
                            position: 'absolute',
                            left: `-${
                              (borderLeft * widthImg) / productData.sizes.width
                            }px`,
                            top: `-${
                              (borderTop * widthImg) / productData.sizes.width -
                              1
                            }px`,
                            width: `${
                              (borderLeft * widthImg) / productData.sizes.width
                            }px`,
                            height: `${
                              (borderTop * widthImg) / productData.sizes.width
                            }px`,
                            backgroundColor: 'rgba(255, 255, 240, 1)',
                          }}
                        ></div>
                      )}
                    </>
                  )} */}
                </Image>
              ) : (
                <UploadOrChangeImage />
              )}
            </div>
          )}
        </div>
        {productData.image &&
          (location.pathname === routeNames.imageSizeAndCrop ||
            location.pathname === routeNames.selectBorder) && (
            <p className={styles.imageUnitTextStyle}>
              {`${calculateDimensions().calculatedWidth}`}
            </p>
          )}
      </div>
    </main>
  );
};

export default ImageEditor;
