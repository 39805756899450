import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { setAxiosSession } from 'utils/auth';
import { getDataFromToken, isTokenExpired } from 'utils/jwt';
import {
  updateUserDetailsApi,
  userLoginApi,
  userRegistrationApi,
} from 'networking/apis/user';
import { useNavigate } from 'react-router-dom';
import { routeNames } from 'constants';
import { useAppDataContext } from 'hooks/useappdatacontext';
import { adminLoginApi } from 'networking/apis/admin';

// authentication context
const AuthContext = createContext();

const AuthProvider = (props) => {
  // routing
  const navigate = useNavigate();
  const { setShowLoader } = useAppDataContext();

  // states
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [userType, setUserType] = useState(null);

  //   Initialize authentication

  const initialize = useCallback(async () => {
    try {
      const loggedIn = localStorage.getItem('loggedIn');
      setIsLoggedIn(loggedIn);
      setIsInitialized(true);
      const token = localStorage.getItem('accessToken');
      if (token && isTokenExpired(token)) {
        setAxiosSession(token);
        const tokenData = await getDataFromToken(token);
        setUserType(tokenData.user_type);
        if (loggedIn) {
          if (tokenData.user_type === 'User') {
            setUserData(tokenData);
          } else if (tokenData.user_type === 'Admin') {
            setAdminData(tokenData);
          }
        }
      } else {
        logOut();
      }
    } catch (error) {
      setIsInitialized(true);
      setIsLoggedIn(false);
      setAuthError(error.message);
    }
  }, [
    setIsLoggedIn,
    setIsInitialized,
    setAuthError,
    setUserData,
    setAdminData,
  ]);

  //  "initialization" on component mount
  useEffect(() => {
    initialize();
  }, [initialize]);

  //   fetch user data from local storage on component mount
  useEffect(() => {
    if (localStorage.getItem('userData')) {
      setUserData(JSON.parse(localStorage.getItem('userData')));
    }
  }, []);

  //   function to register user

  const userRegister = async (registerData) => {
    setShowLoader(true);
    try {
      let response = await userRegistrationApi(registerData);

      const { type, message } = response.data;
      if (type === 'success') {
        setShowLoader(false);
        navigate(routeNames.login);
      } else {
        setShowLoader(false);
        setAuthError(message);
      }
    } catch (error) {
      setShowLoader(false);
      setAuthError(error.message);
      console.log('error', error);
    }
  };

  // function to login user
  const userLogin = async (loginData) => {
    setShowLoader(true);
    try {
      let response = await userLoginApi(loginData);
      const { type, data, token, message } = response.data;
      if (type === 'success') {
        setAxiosSession(token);
        setShowLoader(false);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('loggedIn', true);
        localStorage.setItem('userData', JSON.stringify(data));
        localStorage.setItem('userType', data.account_type);
        setUserType(data.account_type);
        setIsLoggedIn(true);
        setUserData(data);
        navigate(routeNames.home);
      } else {
        setShowLoader(false);
        setIsLoggedIn(false);
        setAuthError(message);
      }
    } catch (error) {
      setShowLoader(false);
      setIsLoggedIn(false);
      setAuthError(error.message);
      console.log('error', error);
    }
  };

  // function to login user
  const adminLogin = async (loginData) => {
    setShowLoader(true);
    try {
      let response = await adminLoginApi(loginData);
      const { type, data, token, message } = response.data;
      if (type === 'success') {
        setAxiosSession(token);
        setShowLoader(false);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('loggedIn', true);
        localStorage.setItem('userData', JSON.stringify(data));
        localStorage.setItem('userType', data.account_type);
        setUserType(data.account_type);
        setIsLoggedIn(true);
        setUserData(data);
        navigate(routeNames.adminDashboard);
      } else {
        setShowLoader(false);
        setIsLoggedIn(false);
        setAuthError(message);
      }
    } catch (error) {
      setShowLoader(false);
      setIsLoggedIn(false);
      setAuthError(error.message);
      console.log('error', error);
    }
  };

  const editUserProfile = async (editUserData) => {
    setShowLoader(true);
    try {
      let response = await updateUserDetailsApi(editUserData);
      console.log('1111', response);

      const { type, message, data } = response.data;
      if (type === 'success') {
        localStorage.setItem('userData', JSON.stringify(data));
        setUserData(data);
        setShowLoader(false);
        navigate(routeNames.home);
      } else {
        setShowLoader(false);
        setAuthError(message);
      }
    } catch (error) {
      setShowLoader(false);
      setAuthError(error.message);
      console.log('error', error);
    }
  };

  // function to logout
  const logOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('userData');
    localStorage.removeItem('userType');
    setUserData(null);
    setAxiosSession(null);
    setIsLoggedIn(false);
    setUserType(null);
  };

  const memoizedValue = useMemo(
    () => ({
      // states:
      isInitialized,
      isLoggedIn,
      authError,
      userData,
      adminData,
      userType,

      // functions:
      userRegister,
      userLogin,
      logOut,
      setAuthError,
      setUserData,
      setAdminData,
      setUserType,
      editUserProfile,
      adminLogin,
    }),
    [
      // states:
      isInitialized,
      isLoggedIn,
      authError,
      userData,
      adminData,
      userType,

      // functions:
      userRegister,
      userLogin,
      logOut,
      setAuthError,
      setUserData,
      setAdminData,
      setUserType,
      editUserProfile,
      adminLogin,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
